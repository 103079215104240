import { Flex } from '@chakra-ui/react';
import { Field } from '../../components/Form';
import Controls from './Controls';
import React, { useEffect } from 'react';
import { useSchemaQuery } from '../../react-apollo-introspection';

const AddBlogLinks = ({ field, index, name, fieldName }) => {
  const [getBlogs, { data, loading, error }] = useSchemaQuery('blogs_List');

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <Flex
      w='100%'
      direction='column'
      align='flex-start'
      rounded='5px'
      bg='white'
      p='15px'
      mb='15px'
    >
      <Controls index={index} />
      <Flex direction='column' w='100%'>
        <Field
          name={`${name}[${index}].title`}
          component='text'
          placeholder='Heading'
          label='Heading'
        />
      </Flex>
      <Flex direction='column' w='100%'>
        <Field
          name={`${name}[${index}].blogs`}
          component='select'
          options={data?.blogs_List?.items}
          placeholder='Select Blog(s)'
          labelKey='title'
          valueKey='_id'
          multiSelect
          isMulti
          // saveFullObject={true}
        />
      </Flex>
    </Flex>
  );
};

export default AddBlogLinks;
