import { gql, useLazyQuery } from '@apollo/client';
import { Box, Button, Stack, useColorModeValue, Flex } from '@chakra-ui/react';

import React from 'react';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';

import PopConfirm from '../../components/PopConfirm';

import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { useMutation } from '@apollo/client';

import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';

const FORGOT_PASSWORD = gql`
  query Query($email: String!, $postmark: postmarkValidationInput) {
    adminuser_ForgotPassword(email: $email, postmark: $postmark)
  }
`;
const DELETE_ADMIN = gql`
  mutation Adminusers_RemoveById($id: MongoID!) {
    adminusers_RemoveById(_id: $id) {
      record {
        email
        name
        _id
      }
      recordId
      error {
        message
      }
    }
  }
`;

const filters = [
  {
    value: 'last_name',
    type: 'text',
    label: 'Last Name',
    isOperator: false,
  },
];

const List = ({ title }) => {
  // const renderRowSubComponent = React.useCallback(
  //     ({ row }) => (
  //         <pre
  //             style={{
  //                 fontSize: '10px',
  //             }}
  //         >
  //             <code>{JSON.stringify({ values: row.values }, null, 2)}</code>
  //         </pre>
  //     ),
  //     []
  // );

  const [fetchData, { loading, error, data }] = useSchemaQuery(FETCH_ALL);
  const [
    removeAdmin,
    { loading: deleteLoading, error: deleteError, data: deleteData },
  ] = useMutation(DELETE_ADMIN);

  const [
    resetPasswordEmail,
    { loading: passwordLoading, error: passwordError, data: passwordData },
  ] = useLazyQuery(FORGOT_PASSWORD);

  const onDelete = (id) => {
    removeAdmin({
      variables: { id: id },
      onCompleted: () => window.location.reload(),
    });
  };
  const columns = [
    {
      Header: '',
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link'>View</Button>
        </Link>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: true,
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Email',
      accessor: 'email',
      disableSortBy: false,
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: 'Actions',
      accessor: '',
      disableSortBy: false,
      Cell: ({ row }) => (
        <Flex flexDirection='row' gap='10px'>
          <Button
            bg='orange.500'
            onClick={() =>
              resetPasswordEmail({
                variables: {
                  email: row?.original?.email,
                  postmark: {
                    callbackUrl: process.env.REACT_APP_ADMIN_RESET_CALLBACK,
                    templateAlias: 'adminResetPassword',
                  },
                },
              })
            }
          >
            Reset Password
          </Button>
          <PopConfirm
            okText='Delete'
            onConfirm={() => onDelete(row?.original?._id)}
            okButtonProps={{
              variantColor: 'error',
            }}
            cancelButtonProps={{
              variantColor: 'gray',
            }}
            title='Are you sure you want to delete this user?  This cannot be undone.'
            w='fit-content'
            bg='red.500'
            p='10px'
            color='white'
            rounded='8px'
            fontWeight='700'
            cursor='pointer'
          >
            Delete user
          </PopConfirm>
        </Flex>
      ),
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml='auto' isInline spacing='15px'>
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt='25px'
        bg={useColorModeValue('white', 'gray.700')}
        shadow='base'
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w='100%'
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
///vwPTSkWPB9CR
