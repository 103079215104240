import React, { Suspense } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Box,
  Text as UIText,
  Skeleton,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/react";

import TimePicker from "../../Fields/TimePicker";

// import { List } from '../../Fields/List'

import ColourPicker from "../../Fields/ColourPicker";
import Text from "../../Fields/Text";
import TextArea from "../../Fields/TextArea";
import Date from "../../Fields/DatePicker";
import RichText from "../../Fields/RichText";
import File from "../../Fields/File";
import Switch from "../../Fields/Switch";
import { Repeater } from "../../Fields/Repeater";
import Select from "../../Fields/Select";
import NativeSelect from "../../Fields/NativeSelect";
import Html from "../../Fields/Html";
import Number from "../../Fields/Number";
import Price from "../../Fields/Price";
import List from "../../Fields/List";
import Video from "../../Fields/Video";
import PageEditor from "../../Fields/PageEditor";
import Mask from "../../Fields/InputMask";
import CheckboxField from "../../Fields/Checkbox";
import InputWithSelect from "../../Fields/InputWithSelect";

const Field = (props) => {
  const {
    component,
    label,
    name,
    isRequired,
    defaultValue,
    guidance,
    help,
    rules,
    onChange,
    auto = false,
    showField = true,
    isConditional,
    isRepeater,
    placeholder,
    switchLabel,
    noMargin,
    blog,
    location,
    gallery,
    ...rest
  } = props;
  const { control } = useFormContext();

  const getField = (props) => {
    switch (component) {
      case "text":
        return <Text {...props} />;
      case "html":
        return <Html {...props} />;
      case "date":
        return <Date {...props} />;
      case "time":
        return <TimePicker {...props} />;
      case "nativeSelect":
        return <NativeSelect {...props} />;
      case "select":
        return <Select {...props} />;
      case "textarea":
        return <TextArea {...props} required={null} />;
      case "richtext":
        return <RichText {...props} />;
      case "switch":
        return <Switch {...props} />;
      case "file":
        return <File {...props} />;
      case "colour":
        return <ColourPicker {...props} />;
      case "number":
        return <Number {...props} />;
      case "price":
        return <Price {...props} />;
      case "video":
        return <Video {...props} />;
      case "list":
        return <List {...props} />;
      case "page":
        return <PageEditor {...props} />;
      case "mask":
        return <Mask {...props} />;
      case "checkbox":
        return <CheckboxField {...props} />;
      case "selectInput":
        return <InputWithSelect {...props} />;

      /* eslint-disable */
      default:
        const Comp = component;
        return <Comp {...props} />;
    }
  };

  return component === "repeater" ? (
    <Repeater name={name} {...props} />
  ) : showField ? (
    <Controller
      name={name}
      defaultValue={defaultValue || ""}
      rules={rules}
      control={control}
      render={({
        field: { onChange, onBlur, value, name },
        fieldState: { invalid, error },
        formState: { errors },
      }) => (
        <Box
          transition="all 0.3s ease-in-out"
          p={isRepeater ? "7px" : "0px"}
          mb={!auto ? 0 : isRepeater ? 0 : 2}
          w="100%"
          value={value}
        >
          <Suspense fallback={<Skeleton />}>
            <FormControl
              mb={isRepeater ? 0 : noMargin ? 0 : 3}
              isInvalid={invalid}
              isRequired={rules && rules.required === true}
            >
              {(label || placeholder) && (
                <FormLabel htmlFor={name} bg="transparent">
                  {label ? label : placeholder ? placeholder : null}{" "}
                </FormLabel>
              )}

              {getField({
                onChange,
                onBlur,
                value,
                name,
                isRepeater,
                placeholder,
                switchLabel,
                error: invalid,
                blog,
                gallery,
                location,
                ...rest,
              })}

              {error && (
                <FormErrorMessage>
                  {error ? (error.message ? error.message : "Required") : null}
                </FormErrorMessage>
              )}

              {help && <FormHelperText>{help}</FormHelperText>}
            </FormControl>
          </Suspense>
        </Box>
      )}
    />
  ) : null;
};

export default Field;
