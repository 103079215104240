import React from "react";
import { Input } from "@chakra-ui/react";

const GenericCustomSearch = ({ setExternalSearch }) => {
  return (
    <Input
      placeholder="Search filter"
      onChange={(e) => {
        if (e.target.value === "") {
          setExternalSearch(null);
        } else {
          setExternalSearch(e.target.value);
        }
      }}
    />
  );
};

//
export default GenericCustomSearch;
