import React from 'react';
import { Field } from '../../../components/Form';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { useWatch } from 'react-hook-form';

const CTA = () => {
  const cta = useWatch({ name: 'cta' });

  return (
    <FormSection>
      <FormSectionInner title="Calls To Action" columns={1}>
        <Field
          component="switch"
          name="cta.showSearchBar"
          label="Show search bar"
        />
        <Field
          component="switch"
          name="cta.hideSearch"
          label="Hide search results"
        />
        <Field component="switch" name="cta.show" label="Show call To action" />
      </FormSectionInner>
      <FormSectionInner>
        {cta?.show && (
          <Field
            component="nativeSelect"
            name="cta.type"
            label="Select Type"
            labelKey="label"
            valueKey="_id"
            selectValue="_id"
            options={[
              { label: 'Newsletter', _id: 'newsletter' },
              { label: 'Download App', _id: 'app' },
            ]}
          />
        )}
      </FormSectionInner>
    </FormSection>
  );
};
export default CTA;
