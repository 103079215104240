import { useLazyQuery } from '@apollo/client';
import { Flex, Grid, Stack } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { EDIT, FETCH } from './graphql';

import { setup } from './setup';

import DeactivateUser from './DeactivateUser';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useLazyQuery(FETCH);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={null}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
        >
          <Stack spacing='20px' w='100%'>
            <FormSection title='Personal Details'>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='first_name'
                  placeholder='First Name'
                  rules={{ required: true }}
                />

                <Field
                  component='text'
                  name='last_name'
                  placeholder='Last Name'
                  rules={{ required: true }}
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='email'
                  placeholder='Email Address'
                  rules={{ required: true }}
                />
                <Field
                  component='text'
                  name='contact_number'
                  placeholder='Contact Number'
                  rules={{ required: true }}
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='date'
                  name='date_of_birth'
                  placeholder='Date of Birth'
                  rules={{ required: true }}
                />
                <Field
                  component='text'
                  name='country'
                  placeholder='Country'
                  rules={{ required: true }}
                />
              </FormSectionInner>
            </FormSection>
          </Stack>
          <Stack spacing='20px' w='100%'>
            <FormSection title='Experience'>
              <FormSectionInner columns={2}>
                <Field
                  component='textarea'
                  name='experience'
                  placeholder='Experience'
                  width='100%'
                  rules={{ required: true }}
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='role_category'
                  placeholder='Role Category'
                  rules={{ required: true }}
                />
                <Field
                  component='text'
                  name='field_category'
                  placeholder='Field Category'
                  rules={{ required: true }}
                />
              </FormSectionInner>
            </FormSection>
            <FormSection title='Talk About Your Self'>
              <FormSectionInner columns={2}>
                <Field
                  component='textarea'
                  name='statement'
                  placeholder='Statement'
                  width='100%'
                  rules={{ required: true }}
                />
              </FormSectionInner>
            </FormSection>
            <FormSection title="Expert's Documents">
              <FormSectionInner columns={3}>
                <Field
                  name='documents'
                  component='repeater'
                  singleName='Document'
                  width='100%'
                  minWidth='100%'
                  label='Documents'
                  isRepeater={true}
                  fieldArray={[
                    {
                      name: 'name',
                      component: 'text',
                      placeholder: 'Document Name',
                      label: 'Document Name',
                    },
                    {
                      name: 'document',
                      component: 'file',
                      placeholder: 'File',
                      label: 'File',
                    },
                  ]}
                />
              </FormSectionInner>
            </FormSection>
            <FormSection title='Profile Picture'>
              <FormSectionInner columns={3}>
                <Field name='profile_picture' component='file' />
              </FormSectionInner>
            </FormSection>
            <FormSection title='Actions'>
              <FormSectionInner columns={3}>
                <DeactivateUser />
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
