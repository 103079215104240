import { useLazyQuery } from '@apollo/client';
import { Flex, FormLabel, Grid, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';
import TabView from './TabView';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);

  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        ignoreValues={["location",'adverts',"categories","videos","photos","tracking","thegoodstuff"]}
        gqlCreate={CREATE}
      >
        <Flex w='80%'>
          <TabView data={data} />
        </Flex>
      </EditView>
    </Meta>
  );
};

export default Index;
