import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Field } from '../../components/Form';
import Controls from './Controls';
import { GrPowerCycle } from 'react-icons/gr';
import Links from './components/Links';

const DownloadAppBlock = ({ field, index, align, name }) => {
  const { setValue, watch } = useFormContext();
  const direction = watch(`${name}[${index}].align`);

  // const changeDirection = () => {
  //   setValue(
  //     `${name}[${index}].align`,
  //     direction === 'left' ? 'right' : 'left'
  //   );
  // };

  return (
    <Flex
      w="100%"
      direction="column"
      align="flex-start"
      rounded="5px"
      bg="white"
      p="15px"
      mb="15px"
    >
      <Flex w="100%" mb="5px">
        <Controls index={index} />
        {/* <Button
          size="sm"
          ml="auto"
          fontWeight="normal"
          leftIcon={<GrPowerCycle />}
          onClick={() => changeDirection()}
        >
          Switch Direction
        </Button> */}
      </Flex>
      <Flex w="100%" direction={direction === 'right' ? 'row' : 'row-reverse'}>
        <Flex flex={1} direction={'column'} justifyContent={'space-evenly'}>
          <Flex direction={'column'}>
            <Text as="label">Desktop</Text>
            <Field component="file" name={`${name}[${index}].image`} max={1} />
          </Flex>
          <Flex direction={'column'}>
            <Text as="label">Mobile</Text>

            <Field
              component="file"
              name={`${name}[${index}].thumbnail`}
              max={1}
            />
          </Flex>
        </Flex>
        <Flex
          direction="column"
          w="100%"
          ml={direction === 'left' ? '20px' : '20px'}
          mr={direction === 'right' ? '20px' : '20px'}
          flex={2}
        >
          <Field
            name={`${name}[${index}].heading`}
            component="text"
            placeholder="Heading"
          />
          <Field
            name={`${name}[${index}].text`}
            component="richtext"
            placeholder="Text Content"
          />
        </Flex>
      </Flex>

      <Flex>
        <Field
          component="file"
          name={`${name}[${index}].apple.thumb`}
          max={1}
        />

        <Field
          name={`${name}[${index}].apple.link`}
          component="text"
          placeholder="https://..."
          label="Link Url"
        />
      </Flex>
      <Flex>
        <Field
          component="file"
          name={`${name}[${index}].google.thumb`}
          max={1}
        />

        <Field
          name={`${name}[${index}].google.link`}
          component="text"
          placeholder="https://..."
          label="Link Url"
        />
      </Flex>
      {/* <Links name={`${name}.link_apple`} imageLink index={index} />
      <Links name={`${name}.link_google`} imageLink index={index} /> */}
    </Flex>
  );
};

export default DownloadAppBlock;
