import {
  Box,
  Divider,
  Flex,
  Grid,
  Image,
  Stack,
  TabPanel,
  Tag,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { Field } from '../../../components/Form';
import { useWatch } from 'react-hook-form';
import { FiClock } from 'react-icons/fi';
import dayjs from 'dayjs';
import { AiOutlineEye, AiOutlineHeart } from 'react-icons/ai';

const AdvertTab = () => {
  const adverts = useWatch({ name: 'adverts' });

  const colours = {
    eat: '#b52828',
    drink: '#6d2db7',
    sleep: '#749079',
    default: 'black',
  };

  const getCategory = (ad) => {
    if (!ad?.categories) return;

    return (
      <Box
        w='5ch'
        bg={
          colours[
            ad?.categories?.eat
              ? 'eat'
              : ad?.categories?.sleep
              ? 'sleep'
              : ad?.categories?.drink
              ? 'drink'
              : 'default'
          ]
        }
        color='white'
        textAlign={'center'}
      >
        {ad?.categories?.eat
          ? 'Eat'
          : ad?.categories?.sleep
          ? 'Sleep'
          : ad?.categories?.drink
          ? 'Drink'
          : 'No Category'}
      </Box>
    );
  };
  return (
    <Flex w='100%' gap={4} direction='column'>
      <Text as='h1' fontSize='1.5em' fontWeight='bold' px='0.5em' mt='1em'>
        Restaurant Adverts
      </Text>
      {adverts?.length > 0 ? (
        adverts.map((ad, index) => {
          return (
            <Flex
              key={ad?._id}
              direction='column'
              p='1em'
              w='100%'
              position='relative'
              bg='white'
              mb='1em'
              minWidth={'700px'}
            >
              <Box position='absolute' top={'-1em'} right={'-1em'}>
                {getCategory(ad)}
              </Box>
              <Box
                w='10ch'
                bg={ad?.active ? 'green' : 'red'}
                color='white'
                textAlign={'center'}
                textTransform={'capitalize'}
                position='absolute'
                top={'2em'}
                right={'-1em'}
              >
                {ad?.active ? 'Active' : 'Not Active'}
              </Box>
              <Box
                w='10ch'
                color={'black'}
                bg='#c5c5c5'
                textAlign={'center'}
                textTransform={'capitalize'}
                position='absolute'
                top={'4em'}
                right={'-1em'}
                py='0.1em'
              >
                <Flex gap={2} align='center' justify='center'>
                  <Flex align='center' gap={'2px'}>
                    <AiOutlineEye fontSize={'14px'} />
                    <Text as='b' fontSize={'0.8em'}>
                      {ad?.tracking?.viewed?.count}
                    </Text>
                  </Flex>
                  <Flex align='center' gap={'2px'}>
                    <AiOutlineHeart fontSize={'14px'} />
                    <Text as='b' fontSize={'0.8em'}>
                      {ad?.tracking?.liked?.count}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
              <Flex w='90%' direction='column'>
                <Flex gap={3} align='center' justify={'center'}>
                  <Box width='150px' h='100px'>
                    <Image
                      width='100%'
                      h='100%'
                      objectFit={'contain'}
                      src={
                        ad?.photos.length > 0
                          ? ad.photos[0].imageUrl
                          : '/placeholder-image.jpeg'
                      }
                    />
                  </Box>
                  <Flex w='100%' direction='column'>
                    <Text fontWeight='bold'>{ad.offer_name}</Text>
                    <Text fontSize='0.8em' w='90%' mt='0.1em'>
                      {ad.offer_terms}
                    </Text>
                    <Flex align='center' gap={2} justify='flex-start' mt='1em'>
                      <FiClock />
                      <Text fontSize='0.8em'>
                        {dayjs(ad?.start_date).format('DD MMM YYYY')} -{' '}
                        {dayjs(ad?.end_date).format('DD MMM YYYY')}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        })
      ) : (
        <Text py='1em'>No Adverts Found</Text>
      )}
      {/* </FormSection> */}
    </Flex>
  );
};

export default AdvertTab;
