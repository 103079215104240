import {
  Badge,
  Box,
  Button,
  Flex,
  Stack,
  Tag,
  Text,
  MenuItem,
  useColorModeValue,
} from '@chakra-ui/react';

import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';
import PlaceById from './components/PlaceById';
import AdvertById from './components/AdvertById';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(
    'reporting_List'
  );

  const columns = [
    {
      Header: 'Report',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          {dayjs(row.original.createdAt).fromNow(true)} ago
        </Flex>
      ),
    },
    {
      Header: 'Report ID',
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <Link to={`/${setup.model}/edit/${row?.original?._id}`}>
            <Button variant='link'>{row?.original?._id} </Button>
          </Link>
        </Flex>
      ),
    },
    {
      Header: 'Place Name',
      accessor: 'advertId',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <PlaceById advertId={row?.original?.advertId} />
        </Flex>
      ),
    },
    {
      Header: 'Advert Name',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <AdvertById advertId={row?.original?.advertId} />
        </Flex>
      ),
    },
    {
      Header: 'Reported Message',
      accessor: 'content',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          {console.log(row, 'row')}
          <Text>{row?.original?.content} </Text>
        </Flex>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <Badge
            bg={
              row?.original?.status === 'read'
                ? 'green.300'
                : row?.original?.status === 'created'
                ? 'yellow.100'
                : 'red.200'
            }
          >
            {row?.original?.status}
          </Badge>
        </Flex>
      ),
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml='auto' isInline spacing='15px'>
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt='25px'
        bg={useColorModeValue('white', 'gray.700')}
        shadow='base'
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w='100%'
      >
        <ListView
          locationFilter={true}
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
        />
      </Box>
    </Meta>
  );
};

export default List;
