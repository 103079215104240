import React from 'react';
import { useRoutes } from 'react-router-dom';

import AdminLayout from '../containers/AdminLayout';
import { Login } from '../containers/Login';
import { ResetPassword } from '../containers/ResetPassword';

import AdminUsers from '../containers/AdminUsers/setup';
import Experts from '../containers/Experts/setup';
import Clients from '../containers/Clients/setup';
import Place from '../containers/Place/setup';
import Locations from '../containers/Locations/setup';
import BusinessUsers from '../containers/BusinessUsers/setup';
import ExplorerUsers from '../containers/ExplorerUsers/setup';
import LocationPages from '../containers/LocationPages/setup';
import Pages from '../containers/Pages/setup';
import Blogs from '../containers/Blogs/setup';
import BlogCategories from '../containers/BlogCategories/setup';
import Pricing from '../containers/Pricing/setup';
import Notifications from '../containers/Notifications/setup';
import Reported from '../containers/Reported/setup';
import Notes from '../containers/Notes/setup';

const routes = [
  {
    path: '/',
    element: React.createElement(AdminLayout),
    children: [
      ...AdminUsers,
      ...Experts,
      ...Clients,
      ...Place,
      ...Notifications,
      ...Notes,
      ...Reported,
      ...Pricing,
      ...Locations,
      ...BusinessUsers,
      ...ExplorerUsers,
      ...Pages,
      ...LocationPages,
      ...Blogs,
      ...BlogCategories,
    ],
  },
  {
    path: '/login',
    element: React.createElement(Login),
  },
  {
    path: '/reset-password',
    element: React.createElement(ResetPassword),
  },
];

const XRoutes = () => {
  const routeSetup = useRoutes(routes);

  return routeSetup;
};

export default XRoutes;
