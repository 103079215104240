export const FETCH_ALL = "reporting_List";

export const FETCH = "reporting_FindById";

export const CREATE = "reporting_CreateOne";

export const EDIT = "reporting_UpdateById";

export const DELETE = "reporting_RemoveManyByIds";


