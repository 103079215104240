import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
  query ListUsers(
    $filter: FilterFindManyUserInput
    $skip: Int
    $limit: Int
    $sort: SortFindManyUserInput
  ) {
    listUsers(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
      _id
      first_name
      last_name
      email
      is_active
    }
  }
`;

export const FETCH = gql`
  query GetUser($filter: FilterFindOneUserInput) {
    getUser(filter: $filter) {
      first_name
      last_name
      email
      is_active
      date_of_birth
      country
      contact_number
      experience
      role_category
      field_category
      statement
      profile_picture
      documents
    }
  }
`;

// export const EDIT = gql`
//   mutation EditUser(
//     $record: UpdateOneUserInput!
//     $filter: FilterUpdateOneUserInput
//   ) {
//     editUser(record: $record, filter: $filter) {
//       _id
//     }
//   }
// `;

export const EDIT = gql`
  mutation editUser($record: UpdateByIdUserInput!, $id: MongoID!) {
    editUser(record: $record, _id: $id) {
      first_name
      last_name
      email
    }
  }
`;

export const DELETE = gql`
  mutation($query: UserQueryInput) {
    deleteManyUsers(query: $query) {
      deletedCount
    }
  }
`;
