import React from 'react';
import { Input } from '@chakra-ui/react';

const CustomTitleSearch = ({ setSearchString }) => {
  return (
    <Input
      placeholder='Search by name'
      onChange={(e) => {
        if (e.target.value === '') {
          setSearchString(null);
        } else {
          setSearchString(e.target.value);
        }
      }}
    />
  );
};

export default CustomTitleSearch;
