import { Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSchemaQuery } from '../../../react-apollo-introspection';
import { gql, useQuery } from '@apollo/client';

const ADVERT = gql`
  query Adverts_FindById($id: MongoID!) {
    adverts_FindById(_id: $id) {
      categories {
        eat
        sleep
        drink
      }
      start_date
      end_date
      offer_name
      offer_terms
      enabled
      placeId
      type
      promotions
      photos {
        imageUrl
        placeId
        menu
        blogId
        adminuserId
        advertId
        _id
        updatedAt
        createdAt
        url
        Path
        ObjectName
        IsDirectory
        ghost
      }
      explorer {
        unique_views {
          count
        }
      }
      tags {
        _id
        value
        category
      }
      _id
      updatedAt
      createdAt
      active
      tracking {
        viewed {
          count
        }
        liked {
          count
        }
      }
    }
  }
`;

const AdvertById = ({ advertId }) => {
  const { loading, error, data } = useQuery(ADVERT, {
    variables: {
      id: advertId,
    },
  });
  console.log(data?.adverts_FindById?.offer_name, 'hi');

  useEffect(() => {
    // fetchData()
  }, []);

  return <Text> {data?.adverts_FindById?.offer_name}</Text>;
};

export default AdvertById;
