import React from 'react';
import { Field } from '../../../components/Form';
import { FormSectionInner } from '../../../components/FormSection';
import { useWatch } from 'react-hook-form';

const Hero = ({ defaultTitle }) => {
  const hero = useWatch({ name: 'hero' });

  return (
    <>
      <FormSectionInner columns={2}>
        <Field component='switch' name='hero.small' label='Small Banner?' />
        {!hero?.small && (
          <Field
            component='file'
            name='hero.banner'
            placeholder='Banner Image'
          />
        )}
      </FormSectionInner>

      <FormSectionInner columns={1}>
        <Field
          imageType='thumbnail'
          name='hero.thumbnail'
          placeholder='Thumbnail'
          component='file'
        />
        <Field
          component='richtext'
          name='hero.title'
          defaultValue={defaultTitle}
          placeholder='Title'
        />
      </FormSectionInner>
    </>
  );
};

export default Hero;
