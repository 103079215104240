import { useLazyQuery } from '@apollo/client';
import { Flex, FormLabel, Grid, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
import { useFieldArray } from 'react-hook-form';
import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);

  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);
  // const { fields, insert, remove } = useFieldArray({
  //   title: ``,
  // });
  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        ignoreValues={['stripe']}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection title="Pricing Details">
              <FormSectionInner columns={2}>
                <Field
                  component="text"
                  name="title"
                  placeholder="Title"
                  disabled={true}
                  rules={{ required: true }}
                />
                <Field
                  component="text"
                  name="subtitle"
                  placeholder="Subtitle"
                />
                <Field
                  component="switch"
                  name="mostPopular"
                  placeholder="Most Popular"
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component="textarea"
                  name="description"
                  placeholder="Description"
                />
              </FormSectionInner>
            </FormSection>
            <FormSection title="Features">
              <FormSectionInner columns={1}>
                <Flex direction="column" w="100%">
                  <Field
                    name={`features`}
                    component="repeater"
                    singleName="feature"
                    layout="vertical"
                    width="100%"
                    minWidth="100%"
                    isRepeater={true}
                    fieldArray={[
                      {
                        name: 'feature',
                        component: 'text',
                        placeholder: 'feature',
                        label: 'Feature',
                      },
                    ]}
                  />
                </Flex>
              </FormSectionInner>
            </FormSection>
            {/* <FormSection title="Pricing">
              <FormSectionInner columns={1}>
                <Flex direction="column" w="100%">
                  <Field
                    name={`stripe.prices`}
                    component="repeater"
                    singleName="text"
                    layout="vertical"
                    width="100%"
                    minWidth="100%"
                    isRepeater={true}
                    fieldArray={[
                      {
                        name: 'unit_amount',
                        component: 'price',
                        placeholder: 'Price',
                        disabled: true,
                        label: 'Price',
                      },
                      {
                        name: 'currency',
                        component: 'text',
                        placeholder: 'Currency',
                        disabled: true,
                        label: 'Currency',
                      },
                    ]}
                  />
                </Flex>
              </FormSectionInner>
            </FormSection> */}
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
