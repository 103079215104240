import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Flex,
  Grid,
  Stack,
  Input,
  Text,
  Box,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useCallback, useState } from 'react';
import { useFormContext, useWatch, setValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Field } from '../../../components/Form';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import {
  useSchemaQuery,
  useSchemaMutation,
} from '../../../react-apollo-introspection';

const Form = ({ fetchData }) => {
  const { type } = useParams();
  const [create, { data: createData, error: createError }] = useSchemaMutation(
    'notifications_CreateOne'
  );
  // const handleCreateNotification = () => {
  //   create({
  //     variables: {
  //       record: {
  //         recieptients: {
  //           explorers: {
  //             all: true,
  //           },
  //         },
  //         message: {
  //           title: "test_title",
  //           body: "test+body",
  //         },
  //       },
  //       fire: true,
  //     },
  //   });
  // };
  return (
    <Flex direction={'column'} w="100%" maxWidth="1000px">
      <>
        <Flex direction={'column'}>
          <Text py="20px" fontSize={'2rem'}>
            Create Notification
          </Text>
          <FormSection>
            <FormSectionInner>
              <Field
                component="text"
                name="message.title"
                placeholder="Notification Title (optional)"
              />
              <Field
                component="text"
                name="message.body"
                placeholder="Notification Body"
              />
              {/* <Flex direction="column" py="20px" gap="10px">
                Send Notifcation Now?
                <Field component="switch" name="fire" />
              </Flex> */}
              <Flex direction="column" py="20px" gap="10px">
                Send to all explorers?
                <Field
                  component="switch"
                  name="recieptients.explorers.all"
                  defaultValue={true}
                />
              </Flex>
            </FormSectionInner>
          </FormSection>
        </Flex>
      </>
    </Flex>
  );
};

export default Form;
