import { gql, useLazyQuery, useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Tag,
  Button,
  Stack,
  useColorModeValue,
  Flex,
  Text,
  Switch,
  Spinner,
} from '@chakra-ui/react';
import { CSVLink } from 'react-csv';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import debounce from 'lodash.debounce';

import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';
import dayjs from 'dayjs';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { useTable } from 'react-table';
const FETCH_CSV =gql`
query Explorerusers_List($perPage: Int) {
  explorerusers_List(perPage: $perPage) {
    items {
      email
      lastName
      firstName
    }
  }
}
`
const FETCH_ALL_USERS =gql`
query Explorerusers_List($perPage: Int, $page: Int, $sort: SortFindManyexplorerusersInput, $filter: FilterFindManyexplorerusersInput) {
  explorerusers_List(perPage: $perPage, page: $page, sort: $sort, filter: $filter) {
    items {
      email
      lastName
      _id
      firstName
    }
    pageInfo {
      currentPage
      perPage
      pageCount
      itemCount
      hasNextPage
      hasPreviousPage
    }
    count
  }
}
`
const columns = [
  {
    Header: 'Explorer ID',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant='link'>View {console.log(row.original,'all')} </Button>
      </Link>
    ),
  },

  {
    Header: 'Customer',
    accessor: 'email',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <Flex direction={'column'}>
        <Flex direction={'row'}>
          <Text mr='1ch'>{row?.original?.firstName}</Text>{' '}
          <Text>{row?.original?.lastName}</Text>
        </Flex>
        <Text
          as='a'
          target='_blank'
          href={`mailto:${value}`}
          textColor={'blue.400'}
          fontWeight={'bold'}
        >
          {value}
        </Text>
      </Flex>
    ),
  },
];

const filters = [
  {
    value: 'last_name',
    type: 'text',
    label: 'Last Name',
    isOperator: false,
  },
];

const List = ({ title }) => {
  const [fetchData,{loading, error, data}] = useLazyQuery(FETCH_ALL_USERS);

  const {loading:AllLoading, error:Allerror, data:AllData }= useQuery(FETCH_CSV,{
variables:{
  perPage: 999
}
  });

  

  const userData = AllData?.[FETCH_ALL]?.items?.map((item) => {
    return {Name:item.firstName,Email:item.email}
  }
  );

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml='auto' isInline spacing='15px'>
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>

        <Stack ml='auto' isInline spacing='15px'>
          {AllLoading ? <Spinner/> :
          <Flex bg="green.500" color="white" px="10px" py="2px" rounded="8px" fontSize={"1rem"} fontWeight={"800"}> <CSVLink
            className='downloadbtn'
            filename='my-file.csv'
            data={userData ? userData : "null"}
            target='_blank'
          >
            Export to CSV
          </CSVLink></Flex>
         }
        </Stack>
      </HeaderWithActions>
      <Box
        mt='25px'
        bg={useColorModeValue('white', 'gray.700')}
        shadow='base'
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w='100%'
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
          
        />
      </Box>
    </Meta>
  );
};

export default List;
