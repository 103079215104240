import { useLazyQuery } from '@apollo/client';
import {
  Text,
  Flex,
  Grid,
  Stack,
  Tag,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  Divider,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_ONE } from './graphql';

import { setup } from './setup';

import DeactivateUser from './DeactivateUser';
import {
  useSchemaMutation,
  useSchemaQuery,
} from '../../react-apollo-introspection';
import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import ResetPassword from './components/ResetPassword';

const Index = ({ title }) => {
  const params = useParams();
  const [hover, setHover] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [
    editDataForVerify,
    { data: editData, loading: editLoading, error: editError },
  ] = useSchemaMutation(EDIT);
  const [
    deleteUserMutation,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useSchemaMutation(DELETE_ONE);
  const redirect = () => {
    window.location.href = '/business-users';
  };
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);
  const deleteUser = () => {
    deleteUserMutation({
      variables: {
        _id: data?.businessusers_FindById?._id,
      },
      onCompleted: () => {
        onClose();
        toast({
          status: 'success',
          description: 'Account Deleted',
          duration: 9000,
        });
        redirect();
      },
    });
    onClose();
  };
  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        ignoreValues={[
          'stripe',
          'emailValidation',
          'token',
          'places',
          'isVerified',
        ]}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
          position={'relative'}
        >
          <Stack spacing='20px' w='100%' position={'relative'}>
            <FormSection title='Personal Details'>
              <Popover>
                <PopoverTrigger>
                  <Button
                    rounded='20px'
                    p='10px'
                    position={'absolute'}
                    right='5'
                    top='5'
                    color='white'
                    fontWeight={'700'}
                    transition={'ease-in-out 0.8s'}
                    overflow={'hidden'}
                    cursor={'pointer'}
                    direction={'column'}
                    textAlign={'center'}
                    justifyContent={'center'}
                    _hover={{
                      bg: data?.businessusers_FindById?.isVerified
                        ? '#309c60'
                        : '#dd0000',
                    }}
                    isDisabled={data?.businessusers_FindById?.isVerified}
                    bg={
                      data?.businessusers_FindById?.isVerified
                        ? '#309c60'
                        : '#dd0000'
                    }
                  >
                    <Text position={'relative'} transition={'ease-in-out 0.8s'}>
                      {data?.businessusers_FindById?.isVerified
                        ? 'Account Verified'
                        : 'Verify Account?'}
                    </Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Are You Sure?</PopoverHeader>
                  <PopoverBody
                    justifyContent={'center'}
                    w='100%'
                    border={'none'}
                  >
                    <Button
                      w='100%'
                      onClick={() => {
                        editDataForVerify({
                          variables: {
                            postmark: {
                              callbackUrl: `https://eatsleepdrinkuk.com/login/business`,
                              templateAlias: 'accountVerified',
                            },
                            _id: data?.businessusers_FindById?._id,
                            record: { isVerified: true },
                          },
                          refetchQueries: [FETCH],
                        });
                      }}
                    >
                      Verify Account
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='firstName'
                  placeholder='First Name'
                  rules={{ required: true }}
                />

                <Field
                  component='text'
                  name='lastName'
                  placeholder='Last Name'
                  rules={{ required: true }}
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='email'
                  placeholder='Email Address'
                  rules={{ required: true }}
                />
                <Flex align='center'>
                  <ResetPassword />
                </Flex>
              </FormSectionInner>
            </FormSection>
          </Stack>
          <Stack spacing='20px' w='100%'>
            <FormSection title='Subscription Details'>
              <FormSectionInner columns={1}>
                <Flex>
                  <Text as='b' mr='1ch'>
                    Stripe ID:
                  </Text>
                  <Text>
                    {data?.businessusers_FindById?.stripe?.customerId}
                  </Text>
                </Flex>
                <Flex>
                  <Text as='b' mr='1ch'>
                    Status:
                  </Text>
                  <Text>
                    {data?.businessusers_FindById?.stripe?.subscription?.status}
                  </Text>
                </Flex>
              </FormSectionInner>
              <FormSectionInner columns={1}>
                <Flex>
                  <Text as='b' mr='1ch'>
                    Tier:
                  </Text>
                  <Text>
                    {
                      data?.businessusers_FindById?.stripe?.subscription
                        ?.product?.metadata?.sub_type
                    }
                  </Text>
                </Flex>
              </FormSectionInner>
              <FormSectionInner columns={3}>
                {data?.businessusers_FindById?.stripe?.subscription?.metadata
                  ?.eat && (
                  <Tag
                    bg='#b52828'
                    color='white'
                    textAlign={'center'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    Eat
                  </Tag>
                )}
                {data?.businessusers_FindById?.stripe?.subscription?.metadata
                  ?.sleep && (
                  <Tag
                    bg='#749079'
                    color='white'
                    textAlign={'center'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    Sleep
                  </Tag>
                )}

                {data?.businessusers_FindById?.stripe?.subscription?.metadata
                  ?.drink && (
                  <Tag
                    bg='#6d2db7'
                    color='white'
                    textAlign={'center'}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    Drink
                  </Tag>
                )}
              </FormSectionInner>
            </FormSection>
          </Stack>
          {console.log(
            data?.businessusers_FindById?.places?.length,
            'data?.businessusers_FindById?.places?.length '
          )}
          {data?.businessusers_FindById?.places?.length > 0 && (
            <Stack spacing='20px' w='100%'>
              <FormSection title='Place Details'>
                <FormSectionInner>
                  <Flex direction={'column'} gap='10px'>
                    {data?.businessusers_FindById?.places?.map((place) => {
                      return (
                        <>
                          <Flex alignItems={'center'}>
                            <Text as='b' mr='1ch'>
                              View:
                            </Text>
                            <a
                              href={`/places/edit/${place?._id}`}
                              target='_blank'
                            >
                              <Button>
                                {place.name
                                  ? place.name
                                  : 'Place not completed'}
                              </Button>
                            </a>
                          </Flex>
                        </>
                      );
                    })}
                  </Flex>
                </FormSectionInner>
              </FormSection>
            </Stack>
          )}

          <Stack w='100%' m='0'>
            <FormSectionInner columns={1} m='0' p='0'>
              <Popover>
                <PopoverTrigger>
                  <Button
                    m='0'
                    rounded='10px'
                    p='30px'
                    color='white'
                    fontWeight={'700'}
                    transition={'ease-in-out 0.8s'}
                    overflow={'hidden'}
                    cursor={'pointer'}
                    direction={'column'}
                    textAlign={'center'}
                    justifyContent={'center'}
                    _hover={{
                      bg:
                        data?.businessusers_FindById?.isSuspended && '#b50202',
                    }}
                    bg={'#b50202'}
                  >
                    <Text position={'relative'} transition={'ease-in-out 0.8s'}>
                      {data?.businessusers_FindById?.isSuspended
                        ? 'Account is Suspended'
                        : 'Suspend This Account?'}
                    </Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>
                    {data?.businessusers_FindById?.isSuspended
                      ? 'Remove Suspension'
                      : 'Are You Sure You Want To Suspend This Account?'}
                  </PopoverHeader>
                  <PopoverBody
                    justifyContent={'center'}
                    w='100%'
                    border={'none'}
                  >
                    <Button
                      w='100%'
                      bg={'#b50202'}
                      _hover={{
                        bg: '#b50202',
                      }}
                      onClick={() => {
                        editDataForVerify({
                          variables: {
                            _id: data?.businessusers_FindById?._id,
                            record: {
                              isSuspended: !data?.businessusers_FindById
                                ?.isSuspended,
                            },
                          },
                          refetchQueries: [FETCH],
                        });
                      }}
                    >
                      {data?.businessusers_FindById?.isSuspended
                        ? 'Remove Suspension?'
                        : 'Suspend Account?'}
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </FormSectionInner>
          </Stack>
          <Divider
            borderColor={'black'}
            borderWidth={'2px'}
            w='99%'
            alignSelf={'center'}
          />
          <Stack w='100%' m='0' mt='50px' position={'relative'} bottom={'0'}>
            <FormSectionInner columns={1} m='0' p='0'>
              <Button
                m='0'
                rounded='10px'
                p='30px'
                color='white'
                fontWeight={'700'}
                transition={'ease-in-out 0.8s'}
                overflow={'hidden'}
                cursor={'pointer'}
                direction={'column'}
                textAlign={'center'}
                justifyContent={'center'}
                onClick={onOpen}
                _hover={{
                  bg: '#b50202',
                }}
                bg={'#b50202'}
              >
                Delete Account?
              </Button>
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Are you sure?</ModalHeader>

                  <ModalCloseButton />
                  <ModalBody>
                    <Text fontWeight={'900'} color='black'>
                      This process cannot be reverted.
                    </Text>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      bg='#b50202'
                      onClick={() => deleteUser()}
                      rounded='8px'
                      color='white'
                      _hover={{ bg: '#b50202', filter: 'brightness(75%)' }}
                    >
                      Delete Account
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </FormSectionInner>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
