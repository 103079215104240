import {
  Flex,
  Grid,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
  FormLabel,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import {
  useSchemaMutation,
  useSchemaQuery,
} from '../../react-apollo-introspection';
import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';
import { FETCH_ALL } from '../Locations/graphql';
import { FETCH_ALL as FETCH_CATEGOREIS } from '../BlogCategories/graphql';
import { useFormContext } from 'react-hook-form';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  const [
    fetchLocations,
    { data: locationData, loading: locationLoading, error: locationError },
  ] = useSchemaQuery(FETCH_ALL);
  const [
    fetchCategories,
    { data: categoryData, loading: categoryLoading, error: categoryError },
  ] = useSchemaQuery(FETCH_CATEGOREIS);

  useEffect(() => {
    fetchCategories({ variables: { perPage: 150 } });
  }, []);

  useEffect(() => {
    if (data?.blogs_FindById?.locations?.length > 0) {
      const _locationIds = data?.blogs_FindById?.locations?.map(
        (each) => each._id
      );
      fetchLocations({
        variables: {
          filter: {
            OR: _locationIds,
          },
        },
      });
    }
  }, [data?.blogs_FindById?.locations]);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        ignoreValues={['locations']}
      >
        <Flex direction='column' maxWidth='75%' w='100%'>
          <Tabs
            orientation='vertical'
            w='100%'
            m='0'
            p='0'
            ml='-40px'
            align='start'
          >
            <TabList>
              <Tab justifyContent={'flexStart'}>General</Tab>
              <Tab justifyContent={'flexStart'}>Content</Tab>
              <Tab justifyContent={'flexStart'}>Seo</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w='100%'
                  maxWidth='1000px'
                  gap='25px'
                >
                  <Stack spacing='20px' w='100%'>
                    <FormSection title='Blog Article'>
                      <FormSectionInner columns={1}>
                        <Field
                          component='text'
                          name='author.name'
                          placeholder='Blog By'
                          rules={{ required: true }}
                        />
                        <FormLabel>Blog Profile Image</FormLabel>
                        <ProfileImage />
                      </FormSectionInner>
                      <FormSectionInner columns={1}>
                        <FormLabel>Banner Photo</FormLabel>
                        <BlogImage />
                      </FormSectionInner>
                      <FormSectionInner columns={1}>
                        <Field
                          component='text'
                          name='title'
                          placeholder='Title'
                          rules={{ required: true }}
                        />
                      </FormSectionInner>

                      <FormSectionInner columns={1}>
                        <Field
                          component='textarea'
                          name='excerpt'
                          placeholder='Excerpt'
                          rules={{ required: true }}
                        />
                      </FormSectionInner>
                    </FormSection>
                    <FormSection title='Categories'>
                      <Stack>
                        <FormSectionInner columns={1}>
                          <Field
                            name={'categories'}
                            component='select'
                            placeholder='Select Category'
                            labelKey={'description'}
                            valueKey='_id'
                            options={categoryData?.blogcategories_List?.items}
                            multiSelect
                            isMulti
                          />
                        </FormSectionInner>
                      </Stack>
                    </FormSection>

                    {/* <FormSection title="Locations">
                      <Stack>
                        <FormSectionInner columns={1}>
                          <Field
                            name={"locations"}
                            component="select"
                            placeholder="Select Location(s)"
                            labelKey={"googleplaces.name"}
                            valueKey="_id"
                            options={locationData?.locations_List?.items}
                            multiSelect
                            isMulti
                            onInputChange={(e) => {
                              fetchLocations({
                                variables: {
                                  regexp: { googleplacesName: e },
                                },
                                fetchPolicy: "network-only",
                              });
                            }}
                            isSearchable
                          />
                        </FormSectionInner>
                      </Stack>
                    </FormSection> */}
                  </Stack>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Grid
                  templateColumns={{ base: '1fr' }}
                  w='100%'
                  maxWidth='1000px'
                  gap='25px'
                >
                  <Stack spacing='20px' w='100%'>
                    <FormSection title='Blog Article'>
                      <FormSectionInner columns={1}>
                        <Field
                          component='page'
                          name='content'
                          placeholder='Article Content'
                          rules={{ required: true }}
                          blog={true}
                          location={true}
                          gallery={true}
                        />
                      </FormSectionInner>
                    </FormSection>
                  </Stack>
                </Grid>
              </TabPanel>
              <TabPanel>
                <Flex mb='20px'>
                  <FormSection title='Seo'>
                    <FormSectionInner columns={1}>
                      <Field
                        component='text'
                        name='seo.title'
                        placeholder='SEO Title'
                      />
                      <Field
                        component='text'
                        name='seo.description'
                        placeholder='SEO Description'
                      />
                      <Field
                        component='text'
                        name='slug'
                        textTransform='lowercase'
                        placeholder='Slug'
                        rules={{ required: true }}
                      />
                    </FormSectionInner>
                  </FormSection>
                </Flex>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </EditView>
    </Meta>
  );
};

const BlogImage = () => {
  const { unregister, watch, isDirty, setValue } = useFormContext();
  const authorImage = watch('image.imageUrl');
  console.log(authorImage, 'image');
  // useEffect(() => {
  //   if (image?.imageUrl) {
  //     console.log(image, '!!!!image');
  //     unregister('image');
  //   }
  // }, [image, isDirty, watch]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteImage, { error, data }] = useSchemaMutation(EDIT);
  const { id } = useParams();
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);

  return (
    <Flex direction={'column'} mb='2rem'>
      {authorImage ? (
        <>
          <Flex position={'relative'} direction={'column'}>
            <Image
              onClick={onOpen}
              src={authorImage}
              style={{ width: '100%' }}
              objectFit='cover'
            />
            <Button
              onClick={() => {
                onOpen();
              }}
            >
              Remove Image
            </Button>
          </Flex>
          <AlertDialog
            isCentered
            isOpen={isOpen}
            // leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Delete Image
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you would like to remove this image?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    colorScheme='red'
                    ml={3}
                    onClick={() => {
                      setValue('image', null);

                      const delObj = {
                        variables: {
                          _id: id,
                          record: { image: { imageUrl: null } },
                        },
                        refetchQueries: [FETCH],
                      };
                      deleteImage(delObj);
                    }}
                  >
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      ) : (
        <Field
          component='file'
          name='image'
          savePath={{ blogId: id }}
          placeholder='Image'
          // rules={{ required: true }}
        />
      )}
    </Flex>
  );
};
const ProfileImage = () => {
  const { unregister, watch, isDirty, setValue } = useFormContext();
  const proFileImage = watch('author.image.imageUrl');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteImage, { error, data }] = useSchemaMutation(EDIT);
  const { id } = useParams();
  useEffect(() => {
    if (data) {
      onClose();
    }
  }, [data]);

  return (
    <Flex direction={'column'} mb='2rem'>
      {proFileImage ? (
        <>
          <Flex position={'relative'} direction={'column'}>
            <Image
              onClick={onOpen}
              src={proFileImage}
              style={{ width: '100%' }}
              objectFit='cover'
            />
            <Button
              onClick={() => {
                onOpen();
              }}
            >
              Remove Image
            </Button>
          </Flex>
          <AlertDialog
            isCentered
            isOpen={isOpen}
            // leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                  Delete Image
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you would like to remove this image?
                </AlertDialogBody>
                {console.log(id, 'id')}
                <AlertDialogFooter>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    colorScheme='red'
                    ml={3}
                    onClick={() => {
                      if (proFileImage) {
                        setValue('author.image', null);
                      }
                      const delObj = {
                        variables: {
                          _id: id,
                          record: { author: { image: { imageUrl: null } } },
                        },
                        refetchQueries: [FETCH],
                      };
                      deleteImage(delObj);
                    }}
                  >
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      ) : (
        <Field
          component='file'
          name='author.image'
          isRequired
          savePath={{ blogId: id }}
          placeholder='Profile Image'
          rules={{ required: true }}
        />
      )}
    </Flex>
  );
};
export default Index;
