import {
  Flex,
  Grid,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Field } from '../../../components/Form';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import CTA from '../../../components/Page/CTA';

const StaticPageView = ({ data, deleteUser }) => {
  const { type } = useParams();

  const { setValue } = useFormContext();

  useEffect(() => {
    type === 'create' && setValue('type', 'location');
  }, [type]);

  return (
    <Flex direction="column" maxWidth="75%" w="100%">
      <Tabs
        orientation="vertical"
        w="100%"
        m="0"
        p="0"
        ml="-40px"
        align="start"
      >
        <TabList>
          <Tab justifyContent={'flexStart'}>General</Tab>
          <Tab justifyContent={'flexStart'}>Content</Tab>
          <Tab justifyContent={'flexStart'}>Advertising</Tab>

          <Tab justifyContent={'flexStart'}>Seo</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Grid
              templateColumns={{ base: '1fr' }}
              w="100%"
              maxWidth="1000px"
              gap="25px"
            >
              <Stack spacing="20px" w="100%">
                <FormSection title="General Details">
                  <FormSectionInner columns={1}>
                    <Field
                      component="text"
                      name="title"
                      placeholder="Name"
                      rules={{ required: true }}
                    />
                    <Field
                      name="hero[0].image"
                      placeholder="Image"
                      component="file"
                    />
                    <Field
                      imageType="thumbnail"
                      name="hero[0].thumbnail"
                      placeholder="Thumbnail"
                      component="file"
                    />
                  </FormSectionInner>
                </FormSection>
                {/* <FormSectionInner columns={1}>
                    <Field component='text' name='url' placeholder='URL' />
                  </FormSectionInner> */}
                <FormSection title={'Hero'}>
                  <FormSectionInner columns={1}>
                    {/* <Field
                      name='hero.[0].description'
                      component='page'
                      rules={{ required: true }}
                      placeholder='Description'
                    /> */}
                    <Field
                      name="hero.[0].description.[0].heading"
                      component="text"
                      rules={{ required: true }}
                      placeholder="Heading"
                    />
                    <Field
                      name="hero.[0].description.[0].text"
                      component="richtext"
                      defaultValue={data?.hero?.[0]?.description?.[0]?.text}
                      rules={{ required: true }}
                      placeholder="Description"
                    />
                    <Field
                      name="hero.[0].description.[0].type"
                      component="hidden"
                      value="textWithHeading"
                      placeholder=""
                    />
                  </FormSectionInner>
                  <FormSectionInner columns={1}>
                    <Field
                      name="hero.[0].readmore.[0].heading"
                      component="text"
                      rules={{ required: true }}
                      placeholder="Read more heading"
                    />
                    <Field
                      name="hero.[0].readmore.[0].text"
                      component="richtext"
                      defaultValue={data?.hero?.[0]?.readmore?.[0]?.text}
                      rules={{ required: true }}
                      placeholder="Read more description"
                    />
                    <Field
                      name="hero.[0].readmore.[0].type"
                      component="hidden"
                      value="textWithHeading"
                    />
                  </FormSectionInner>
                </FormSection>
                <CTA />
              </Stack>
            </Grid>
          </TabPanel>
          <TabPanel>
            <FormSectionInner columns={1}>
              <Field
                name="content"
                component="page"
                blog={true}
                location={true}
                gallery={true}
              />
            </FormSectionInner>
          </TabPanel>
          <TabPanel>
            <AdvertComponent />
          </TabPanel>
          <TabPanel>
            <Flex mb="20px">
              <FormSection title="Seo">
                <FormSectionInner columns={1}>
                  <Field
                    component="text"
                    name="seo.title"
                    placeholder="SEO Title"
                  />
                  <Field
                    component="text"
                    name="seo.description"
                    placeholder="SEO Description"
                  />
                  <Field component="text" name="slug" placeholder="Page slug" />
                </FormSectionInner>
              </FormSection>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

const AdvertComponent = () => {
  return (
    <FormSection title="Advertise">
      <FormSectionInner columns={1}>
        {' '}
        <Field
          name="cta.adspace.active"
          component="switch"
          placeholder="Enable Ad?"
        />
        <Field name="hero[0].image" placeholder="Image" component="file" />
        <Field
          imageType="thumbnail"
          name="hero[0].thumbnail"
          placeholder="Thumbnail"
          component="file"
        />
        <Field
          name="cta.adspace.heading"
          component="text"
          placeholder="Advert heading"
        />
        <Field
          name="cta.adspace.subheading"
          component="text"
          placeholder="Sub-heading"
        />
        <Field
          name="cta.adspace.link"
          component="text"
          placeholder="Link url"
        />
      </FormSectionInner>
    </FormSection>
  );
};

export default StaticPageView;
