import React from 'react';
import {
  Flex,
  Grid,
  Modal,
  Image,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  Button,
  Text,
  Divider,
} from '@chakra-ui/react';
import { PiScribbleLoopBold } from "react-icons/pi";
import {
  TfiMobile,
  TfiLayoutMediaLeft,
  TfiLayoutMediaRight,
  TfiLayoutCtaCenter,
  TfiLayoutListThumbAlt,
  TfiGallery,
  TfiLocationPin,
} from 'react-icons/tfi';
import { FaAppStore } from "react-icons/fa";
const Select = ({ children, onClick, image, icon }) => (
  <Flex
    w="100%"
    rounded="5px"
    onClick={onClick}
    border="1px"
    borderColor="gray.300"
    p="10px"
    align="center"
    _hover={{ cursor: 'pointer', borderColor: 'gray.500' }}
  >
    {icon}
    {/* <Image src={image} w='100px' opacity={0.3} mr='20px' /> */}
    <Text fontSize="16px" ml="10px">
      {children}
    </Text>
  </Flex>
);

const SelectModal = ({
  isOpen,
  onClose,
  addBlock,
  blog,
  location,
  gallery,
}) => {
  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Page Block</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(1fr)" gap="20px">
            <Select
              onClick={() => addBlock('textWithHeading')}
              icon={<TfiLayoutCtaCenter fontSize="2rem" />}
            >
              Text with Heading
            </Select>
            <Select
              onClick={() => addBlock('textImageRight')}
              icon={<TfiLayoutMediaRight fontSize="2rem" />}
            >
              <Text>Text & Right Image</Text>
            </Select>
            <Select
              onClick={() => addBlock('textImageLeft')}
              icon={<TfiLayoutMediaLeft fontSize="2rem" />}
            >
              <Text>Text & Left Image</Text>
            </Select>

            <Select
              onClick={() => addBlock('appDownloadCTA')}
              icon={<TfiMobile fontSize="2rem" />}
            >
              <Text>Download App Block</Text>
            </Select>
            <Select
              onClick={() => addBlock('getTheApp')}
              icon={<FaAppStore fontSize="2rem" />}
            >
              <Text>Get The App Block</Text>
            </Select>
            <Select
              onClick={() => addBlock('stayInTheLoop')}
              icon={<PiScribbleLoopBold fontSize="2rem" />}
            >
              <Text>Stay In The Loop</Text>
            </Select>

            <Divider />
            {blog && (
              <Select
                onClick={() => addBlock('addBlogLinks')}
                icon={<TfiLayoutListThumbAlt fontSize="2rem" />}
              >
                <Text>Add Blog Links</Text>
              </Select>
            )}
            {gallery && (
              <Select
                onClick={() => addBlock('addGalleryImages')}
                icon={<TfiGallery fontSize="2rem" />}
              >
                <Text>Add Gallery Images</Text>
              </Select>
            )}
            {gallery && (
              <Select
                onClick={() => addBlock('AddGalleryImagesRow')}
                icon={<TfiGallery fontSize="2rem" />}
              >
                <Text>Add Gallery Row Images</Text>
              </Select>
            )}
            {location && (
              <Select
                onClick={() => addBlock('addFeaturedLocations')}
                icon={<TfiLocationPin fontSize="2rem" />}
              >
                <Text>Add Featured Locations</Text>
              </Select>
            )}
          </Grid>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SelectModal;
