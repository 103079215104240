import React, { useEffect, useState } from 'react';
import {
  useSchemaMutation,
  useSchemaQuery,
} from '../../react-apollo-introspection';
import {
  Flex,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Image,
  Box,
  Skeleton,
} from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import { RiDeleteBin6Line } from 'react-icons/ri';
//a basic html file upload component
const Bunny = (props) => {
  const { name, imageType, onChange, value, path, savePath } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [bunnyData, setBunnyData] = useState(null);

  const [
    uploadImage,
    { data: uploadData, error: uploadError, loading },
  ] = useSchemaMutation('bunnyFile_CreateOne');

  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const watchField = watch(name);

  const [call, { data, called }] = useSchemaQuery('bunnyFile_GetUploadData', {
    variables: {
      storagePath: path ? `/${path}` : '/nuclues-uploads',
    },
    fetchPolicy: 'network-only',
  });

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setIsLoaded(false);
    call().then((res) => {
      setIsLoaded(false);

      setBunnyData(res.data);

      return res.data.bunnyFile_GetUploadData;
    });
  };

  useEffect(() => {
    if (selectedFile && bunnyData) handleSubmit(bunnyData);
  }, [selectedFile, bunnyData]);

  useEffect(() => {
    let vars = {};
    if (savePath) {
      const key = Object?.keys?.(savePath)?.[0];
      const val = savePath?.[key];

      vars = {
        imageUrl,
        // name: selectedFile?.name,
        // storagePath: path ? `/${path}` : '/nuclues-uploads',
        [key]: val,
      };
    } else {
      vars = {
        imageUrl,
        // name: selectedFile?.name,
        // storagePath: path ? `/${path}` : '/nuclues-uploads',
      };
    }

    if (imageUrl) {
      setValue(name, { imageUrl: imageUrl });
      uploadImage({
        variables: {
          record: vars,
        },
        fetchPolicy: 'network-only',
        refetchQueries: ['businessuser_Current'],
      });
    }
  }, [imageUrl, savePath]);

  useEffect(() => {
    if (uploadData) {
      return setValue(name, {
        imageUrl: uploadData?.bunnyFile_CreateOne?.record?.imageUrl,
      });
    }
  }, [uploadData?.bunnyFile_CreateOne?.recordId]);

  const handleSubmit = async (data) => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
      headers: data?.bunnyFile_GetUploadData?.headers,
      body: formData,
    };

    fetch(data?.bunnyFile_GetUploadData?.uploadUrl, requestOptions, {
      onError: function(error) {},
      onProgress: function(bytesUploaded, bytesTotal) {
        const progress = Math.round((bytesUploaded / bytesTotal) * 100);
        console.log(progress, '!!!!! data progress');
        setProgress(progress);
      },
      onSuccess: function() {},
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log('!!!!! data great susceess?', result);
        setIsLoaded(true);
        setImageUrl(result.imageUrl);
        setValue(name, { imageUrl: result.imageUrl });
      })
      .catch((error) => console.log('!!!!! data  error', error));
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteImage] = useSchemaMutation('bunnyFile_Delete');

  const handleDeleteFile = (id) => {
    console.log('delete FIRED');
    deleteImage({
      variables: { _id: id },
    });

    setValue(name, {});
    setSelectedFile(null);
    onClose();
  };

  return (
    <div>
      <Flex direction="column" w="100%">
        {watchField?.imageUrl ? (
          <Box
            position={'relative'}
            width={imageType === 'thumbnail' ? '150px' : '100%'}
          >
            <Image src={watchField?.imageUrl || imageUrl} width={'100%'} />
            <Box
              position="absolute"
              right="0"
              top="0"
              padding="0.5rem"
              backgroundColor="rgba(0,0,0,0.5)"
              onClick={onOpen}
            >
              <RiDeleteBin6Line
                color="white"
                cursor="pointer"
                fontSize="1.5rem"
              />
            </Box>
          </Box>
        ) : (
          <>
            <input type="file" name={name} onChange={handleFileChange} />
            {selectedFile && (
              <Skeleton height="100px" width={'100%'} isLoaded={isLoaded}>
                {/* <Button w='100%' type='submit' onClick={(e) => handleSubmit(e)}>
                Upload
              </Button> */}
              </Skeleton>
            )}
          </>
        )}

        <AlertDialog
          isOpen={isOpen}
          // leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete File
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  colorScheme="red"
                  onClick={() => handleDeleteFile(watchField?._id)}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </div>
  );
};

export default Bunny;
