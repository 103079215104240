import { useLazyQuery } from '@apollo/client';
import {
  Text,
  Flex,
  Grid,
  Stack,
  Tag,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  useDisclosure,
  ModalBody,
  ModalCloseButton,
  Divider,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { CREATE, EDIT, FETCH, DELETE_ONE } from './graphql';
import { Redirect } from 'react-router-dom';
import { setup } from './setup';

import DeactivateUser from './DeactivateUser';
import {
  useSchemaMutation,
  useSchemaQuery,
} from '../../react-apollo-introspection';
import { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import ResetPassword from './components/ResetPassword';

const Index = ({ title }) => {
  const params = useParams();
  const [hover, setHover] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [
    editDataForVerify,
    { data: editData, loading: editLoading, error: editError },
  ] = useSchemaMutation(EDIT);
  const [
    deleteUserMutation,
    { data: deleteData, loading: deleteLoading, error: deleteError },
  ] = useSchemaMutation(DELETE_ONE);

  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  const redirect = () => {
    window.location.href = '/explorer-users';
  };
  const deleteUser = () => {
    deleteUserMutation({
      variables: {
        _id: params?.id,
      },
      onCompleted: () => {
        onClose();
        toast({
          status: 'success',
          description: 'Account Deleted',
          duration: 9000,
        });
        redirect();
      },
    });
    onClose();
  };
  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        ignoreValues={['emailValidation']}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w='100%'
          maxWidth='1000px'
          gap='25px'
          position={'relative'}
        >
          <Stack spacing='20px' w='100%' position={'relative'}>
            <FormSection title='Personal Details'>
              {/* <Popover>
                <PopoverTrigger>
                  <Button
                    rounded="20px"
                    p="10px"
                    position={"absolute"}
                    right="5"
                    top="5"
                    color="white"
                    fontWeight={"700"}
                    transition={"ease-in-out 0.8s"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                    direction={"column"}
                    textAlign={"center"}
                    justifyContent={"center"}
                    _hover={{
                      bg: data?.explorerusers_FindById?.isVerified
                        ? "#309c60"
                        : "#dd0000",
                    }}
                    isDisabled={data?.explorerusers_FindById?.isVerified}
                    bg={
                      data?.explorerusers_FindById?.isVerified
                        ? "#309c60"
                        : "#dd0000"
                    }
                  >
                    <Text position={"relative"} transition={"ease-in-out 0.8s"}>
                      {data?.explorerusers_FindById?.isVerified
                        ? "Account Verified"
                        : "Verify Account?"}
                    </Text>
                  </Button>
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Are You Sure?</PopoverHeader>
                  <PopoverBody
                    justifyContent={"center"}
                    w="100%"
                    border={"none"}
                  >
                    <Button
                      w="100%"
                      onClick={() => {
                        editDataForVerify({
                          variables: {
                            postmark: {
                              callbackUrl: `https://eatsleepdrinkuk.com/login/business`,
                              templateAlias: "accountVerified",
                            },
                            _id: data?.explorerusers_FindById?._id,
                            record: { isVerified: true },
                          },
                          refetchQueries: [FETCH],
                        });
                      }}
                    >
                      Verify Account
                    </Button>
                  </PopoverBody>
                </PopoverContent>
              </Popover> */}

              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='firstName'
                  placeholder='First Name'
                  rules={{ required: true }}
                />

                <Field
                  component='text'
                  name='lastName'
                  placeholder='Last Name'
                  rules={{ required: true }}
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='email'
                  placeholder='Email Address'
                  rules={{ required: true }}
                />
                <Flex align='center'>
                  <ResetPassword />
                </Flex>
              </FormSectionInner>
            </FormSection>
          </Stack>

          <Divider
            borderColor={'black'}
            borderWidth={'2px'}
            w='99%'
            alignSelf={'center'}
          />
          <Stack w='100%' m='0' mt='50px' position={'relative'} bottom={'0'}>
            <FormSectionInner columns={1} m='0' p='0'>
              <Button
                m='0'
                rounded='10px'
                p='30px'
                color='white'
                fontWeight={'700'}
                transition={'ease-in-out 0.8s'}
                overflow={'hidden'}
                cursor={'pointer'}
                direction={'column'}
                textAlign={'center'}
                justifyContent={'center'}
                onClick={onOpen}
                _hover={{
                  bg: '#b50202',
                }}
                bg={'#b50202'}
              >
                Delete Account?
              </Button>
              <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Are you sure?</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text fontWeight={'900'} color='black'>
                      This process cannot be reverted.
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      bg='#b50202'
                      onClick={() => deleteUser()}
                      rounded='8px'
                      color='white'
                      _hover={{ bg: '#b50202', filter: 'brightness(75%)' }}
                    >
                      Delete Account
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </FormSectionInner>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
