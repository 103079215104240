export default function getTypeFields (schema, name, tab) {
    const type = schema.types.find(e => e.name === name)
    let str = ``
    if (!tab) tab = 0
    tab++
    const getTabs = (tab_in) => {
        let str = `\n`
        for (let i=0; i < tab_in; i++) {
            str += `\t`
        }
        return str
    }

    if (type?.fields) {
        str += `{ `
        type.fields.forEach(field => {
            const { ofType, name } = field.type
            let nested
            switch(field.type.kind) {
                case 'SCALAR': str += `${getTabs(tab)}${field.name} `
                    break
                case 'LIST':
                    switch (ofType.kind) {
                        case 'NON_NULL':
                            nested = getTypeFields(schema, ofType.ofType.name, tab)
                            break
                        case 'OBJECT':
                            nested = getTypeFields(schema, ofType.name, tab)
                            break
                        case 'SCALAR':
                            nested = ``
                            break
                        default: 
    
                            console.log('list kind not recognised', ofType.kind, field)
                    }
                    str += `${getTabs(tab)}${field.name} ${nested} `
                    break
                
                case 'NON_NULL':
                    
                    switch (ofType.kind) {
                        case 'SCALAR':
                            str += `${getTabs(tab)}${field.name}`
                            break
                        case 'OBJECT':
                            nested = getTypeFields(schema, ofType.name, tab)
                            str += `${getTabs(tab)}${field.name} ${nested} `
                            break
                        case 'LIST':
                            console.log(field);
                            if (ofType.ofType.kind === 'SCALAR') {
                                str += `${getTabs(tab)}${field.name}`

                            } else {
                                nested = getTypeFields(schema, ofType.ofType.ofType.name, tab)
                                str += `${getTabs(tab)}${field.name} ${nested} `
                            }

                            break
                        case 'ENUM':
                            //console.log('>>>>enum', field.name)
                            str += `${getTabs(tab)}${field.name}`
                            break
                        default:
                            console.log('other, non null', field)
                    }
                    break
                case 'OBJECT':
                    //console.log({ field });
                    const object = getTypeFields(schema, name, tab)
                    str += `${getTabs(tab)}${field.name} ${object}`
                    break
                case 'INTERFACE':
                    const i = getTypeFields(schema, name, tab)
                    str += `${getTabs(tab)}${field.name} ${i}`
                    break
                case 'ENUM':
                    str += `${getTabs(tab)}${field.name}`
                    //console.log('>>enum', { field });
                    break
                default: console.log('other field type', field.type.kind);
            }
        })
        tab--
        str += `${getTabs(tab)}}`
        //str += `} \n`
    }
    return str
    
}