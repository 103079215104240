import { Alert, AlertIcon } from '@chakra-ui/react';
import React from 'react';

const GraphQLError = ({ error }) => {
    return error ? (
        <Alert my='10px' status='error'>
            <AlertIcon />
            {error.graphQLErrors[0].message}
        </Alert>
    ) : null;
};

export default GraphQLError;
