// import { gql } from '@apollo/client';

// export const FETCH_ALL = gql`
//     query Query(
//         $filter: FilterFindManyAdminUserInput
//         $skip: Int
//         $limit: Int
//         $sort: SortFindManyAdminUserInput
//     ) {
//         listAdminUsers(
//             filter: $filter
//             skip: $skip
//             limit: $limit
//             sort: $sort
//         ) {
//             _id
//             name
//             email
//             isAdmin
//             updatedAt
//             createdAt
//             permission_role {
//                 title
//                 _id
//                 permissions {
//                     title
//                     code
//                     _id
//                 }
//             }
//         }
//     }
// `;

// export const FETCH = gql`
//     query GetAdminUser($filter: FilterFindOneAdminUserInput) {
//         getAdminUser(filter: $filter) {
//             name
//             email
//             permission_role {
//                 title
//                 permissions {
//                     title
//                     description
//                     code
//                     _id
//                 }
//                 _id
//             }
//         }
//     }
// `;

// export const CREATE = gql`
//     mutation Mutation($record: CreateOneAdminUserInput!) {
//         createAdminUser(record: $record) {
//             _id
//         }
//     }
// `;

// export const EDIT = gql`
//     mutation EditAdminUser(
//         $record: UpdateOneAdminUserInput!
//         $filter: FilterUpdateOneAdminUserInput
//     ) {
//         editAdminUser(record: $record, filter: $filter) {
//             _id
//         }
//     }
// `;

// export const DELETE = gql`
//     mutation($query: UserQueryInput) {
//         deleteManyUsers(query: $query) {
//             deletedCount
//         }
//     }
// `;

// export const FETCH_ROLES = gql`
//     query FetchRoles {
//         listAdminPermissionRoles {
//             items {
//                 _id
//                 title
//             }
//         }
//     }
// `;

export const FETCH_ALL = 'adminusers_List';

export const FETCH = 'adminusers_FindById';

export const FETCH_ROLES = 'permissionroles_List';

export const CREATE = 'adminuser_Register';

export const EDIT = 'adminusers_UpdateById';

// export const DELETE = 'adminusers_RemoveManyByIds';
export const DELETE = 'adminusers_RemoveById';
export const PASSWORD_RESET = 'adminuser_ForgotPassword';

