import { useLazyQuery } from "@apollo/client";
import { Button, Flex, Grid, Stack } from "@chakra-ui/react";
import React from "react";
import { useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import EditView from "../../components/EditView";
import { Field } from "../../components/Form";
import { FormSection, FormSectionInner } from "../../components/FormSection";
import Meta from "../../components/Meta";
import { useSchemaQuery } from "../../react-apollo-introspection";
import { authAtom } from "../../state/auth";
import Form from "./Components/Form";
import { CREATE, EDIT, FETCH } from "./graphql";

import { setup } from "./setup";

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        editFields={["landing"]}
      >
        <Form fetchData={data} />
      </EditView>
    </Meta>
  );
};

export default Index;
