import React, { useEffect, useState } from 'react';
import { useSchemaQuery } from '../../../react-apollo-introspection';
import {
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Tooltip,
  Button,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { useSchemaMutation } from '../../../react-apollo-introspection';

import CreateCustomLocalityModal from './CreateCustomLocalityModal';
const CustomLocality = ({ fetchData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentLocation, setCurrentLocation] = useState(null);
  const cancelRef = React.useRef();
  const [fetchCustomLocations, { data, loading, error }] = useSchemaQuery(
    'locations_List'
  );
  const [
    deleteLocation,
    { data: deleteData, error: deleteError },
  ] = useSchemaMutation('locations_RemoveById');

  useEffect(() => {
    if (deleteData) {
      handleFetchCustomLocations();
    }
  }, [deleteData]);

  useEffect(() => {
    if (!data) {
      handleFetchCustomLocations();
    }
  }, [data, fetchData]);

  const handleFetchCustomLocations = () => {
    fetchCustomLocations({
      variables: {
        filter: {
          googleplaces: {
            types: ['custom_locality'],
            custom: {
              address_components: [
                {
                  long_name: fetchData?.locations_FindById?.googleplaces?.name,
                  short_name: fetchData?.locations_FindById?.googleplaces?.name,
                  types: fetchData?.locations_FindById?.googleplaces?.types,
                },
              ],
            },
          },
        },
        perPage: 1000,
      },
    });
  };
  const handleDelete = () => {
    deleteLocation({
      variables: {
        _id: currentLocation?._id,
      },
    });
    onClose();
  };

  useEffect(() => {
    if (currentLocation) {
      onOpen();
    }
  }, [currentLocation]);
  return (
    <Flex flexWrap='wrap'>
      {loading && <Spinner />}
      {data?.locations_List?.items.length > 0 &&
        data?.locations_List?.items?.map((location) => (
          <Tag m='5px' size='lg' variant='solid' colorScheme='teal'>
            <Tooltip
              label={location?.googleplaces?.address_components?.map((each) => {
                return <Text>{each.long_name}</Text>;
              })}
            >
              <TagLabel> {location.googleplaces?.name} </TagLabel>
            </Tooltip>
            <TagCloseButton onClick={() => setCurrentLocation(location)} />
          </Tag>
        ))}

      <CreateCustomLocalityModal
        placeID={fetchData?.locations_FindById?.googleplaces?.place_id}
        address_components={
          {
            long_name: fetchData?.locations_FindById?.googleplaces?.name,
            short_name: fetchData?.locations_FindById?.googleplaces?.name,
            types: fetchData?.locations_FindById?.googleplaces?.types,
          }
          // fetchData?.locations_FindById?.googleplaces?.address_components
        }
        refresh={handleFetchCustomLocations}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Location
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={() => handleDelete()} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default CustomLocality;
