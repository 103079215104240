import { gql, useMutation } from '@apollo/client';
import { Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import PopConfirm from '../../components/PopConfirm';

const DEACTIVATE = gql`
  mutation DeactivateUser($user_id: ID) {
    deactivateUser(user_id: $user_id) {
      success
    }
  }
`;

const REACTIVATE = gql`
  mutation ReactivateUser($user_id: ID) {
    activateUser(user_id: $user_id) {
      success
    }
  }
`;

const DeactivateUser = () => {
  const { id } = useParams();
  const status = useWatch({ name: 'is_active' });

  const [deactivate, { loading, data: deactivateResponse }] = useMutation(
    DEACTIVATE
  );
  const [
    reactivate,
    { loading: activeLoading, data: reactivateResponse },
  ] = useMutation(REACTIVATE);

  const handleDeactivate = () => {
    deactivate({ variables: { user_id: id }, refetchQueries: ['GetUser'] });
  };

  const handleActivate = () => {
    reactivate({ variables: { user_id: id }, refetchQueries: ['GetUser'] });
  };

  return (
    <Flex>
      {status === true && (
        <PopConfirm
          onConfirm={() => handleDeactivate()}
          title='Are you sure you want to deactivate this user? '
        >
          <Button colorScheme='red' isLoading={loading} isDisabled={loading}>
            Deactivate User
          </Button>
        </PopConfirm>
      )}
      {status === false && (
        <PopConfirm
          onConfirm={() => handleActivate()}
          title='Are you sure you want to reactivate this user? '
        >
          <Button
            colorScheme='green'
            isLoading={activeLoading}
            isDisabled={activeLoading}
          >
            Reactivate User
          </Button>
        </PopConfirm>
      )}
    </Flex>
  );
};

export default DeactivateUser;
