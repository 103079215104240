import {
  Flex,
  Grid,
  Stack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Field } from '../../../components/Form';
import { FormSection, FormSectionInner } from '../../../components/FormSection';

import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Hero from '../../../components/Page/Hero';
import CTA from '../../../components/Page/CTA';

const StaticPageView = ({ data, deleteUser }) => {
  const { type } = useParams();

  const { setValue } = useFormContext();

  useEffect(() => {
    type === 'create' && setValue('type', 'page');
  }, [type]);

  return (
    <Flex direction="column" maxWidth="75%" w="100%">
      <Tabs
        orientation="vertical"
        w="100%"
        m="0"
        p="0"
        ml="-40px"
        align="start"
      >
        <TabList>
          <Tab justifyContent={'flexStart'}>General</Tab>
          <Tab justifyContent={'flexStart'}> Content </Tab>
          <Tab justifyContent={'flexStart'}>Seo</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Grid
              templateColumns={{ base: '1fr' }}
              w="100%"
              maxWidth="1000px"
              gap="25px"
            >
              <Stack spacing="20px" w="100%">
                <FormSection title="General Details">
                  <FormSectionInner columns={1}>
                    <Field
                      component="text"
                      name="title"
                      placeholder="Title"
                      rules={{ required: true }}
                    />
                  </FormSectionInner>
                </FormSection>
              </Stack>

              <Stack spacing="20px" w="100%">
                <Stack spacing="20px" w="100%">
                  <FormSection>
                    <FormSectionInner title="Hero">
                      <Hero defaultTitle={data?.hero?.title} />
                    </FormSectionInner>
                  </FormSection>
                </Stack>
              </Stack>
              <Stack spacing="20px" w="100%">
                <Stack spacing="20px" w="100%">
                  <CTA />
                </Stack>
              </Stack>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Grid
              templateColumns={{ base: '1fr' }}
              w="100%"
              maxWidth="1000px"
              gap="25px"
            >
              <Stack spacing="20px" w="100%">
                <FormSection title="General Details">
                  <FormSectionInner columns={1}>
                    <Field
                      component="page"
                      name="content"
                      placeholder="Header"
                      blog={true}
                      location={true}
                      gallery={true}
                    />
                  </FormSectionInner>
                </FormSection>
              </Stack>
            </Grid>
          </TabPanel>
          <TabPanel>
            <Flex mb="20px">
              <FormSection title="Seo">
                <FormSectionInner columns={1}>
                  <Field
                    component="text"
                    name="seo.title"
                    placeholder="SEO Title"
                  />
                  <Field
                    component="text"
                    name="seo.description"
                    placeholder="SEO Description"
                  />
                  <Field
                    component="text"
                    name="slug"
                    textTransform="lowercase"
                    placeholder="Slug"
                    rules={{ required: true }}
                  />
                </FormSectionInner>
              </FormSection>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default StaticPageView;
