import {
    Badge,
    Button,
    Flex,
    Stack,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormSection } from '../../components/FormSection';
import NewUser from './NewUser';
import RemoveUser from './RemoveUser';
import ResendInvite from './ResendInvite';

const ClientUsers = () => {
    const { watch } = useFormContext();
    const { type } = useParams();
    const users = watch('users');

    return type === 'edit' ? (
        <FormSection
            title={
                <Flex align='center' w='100%'>
                    Client Users
                    <NewUser />
                </Flex>
            }
        >
            <Table size='sm' variant='striped'>
                <Thead>
                    <Tr>
                        <Th>First Name</Th>
                        <Th>Last Name</Th>
                        <Th>Email Address</Th>
                        <Th>Role</Th>
                        <Th>Status</Th>
                        <Th>Actions</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {users?.map((user, i) => (
                        <Tr key={`user-${i}`}>
                            <Td>{user.first_name}</Td>
                            <Td>{user.last_name}</Td>
                            <Td>{user.email}</Td>
                            <Td>{user.permission_role}</Td>
                            <Td>
                                <Badge
                                    variant='solid'
                                    colorScheme={
                                        user.status === 'PENDING'
                                            ? 'orange'
                                            : 'green'
                                    }
                                >
                                    {user.status === 'PENDING'
                                        ? 'Pending'
                                        : 'Active'}
                                </Badge>
                            </Td>
                            <Td>
                                <Stack isInline spacing='10px'>
                                    <RemoveUser user={user} />
                                    <ResendInvite user={user} />
                                </Stack>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </FormSection>
    ) : null;
};

export default ClientUsers;
