import { gql } from '@apollo/client';

export const FETCH_ALL = gql`
    query ListClients(
        $filter: FilterFindManyClientInput
        $skip: Int
        $limit: Int
        $sort: SortFindManyClientInput
    ) {
        clientsList(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            _id
            title
        }
    }
`;

export const FETCH = gql`
    query GetClient($filter: FilterFindOneClientInput) {
        clientGet(filter: $filter) {
            title
            users {
                _id
                first_name
                last_name
                email
                status
                permission_role
            }
        }
    }
`;

export const EDIT = gql`
    mutation editClient(
        $record: UpdateOneClientInput!
        $filter: FilterUpdateOneClientInput
    ) {
        clientEdit(record: $record, filter: $filter) {
            recordId
        }
    }
`;

export const CREATE = gql`
    mutation ClientCreate($record: ClientInput) {
        clientCreate(record: $record) {
            email
        }
    }
`;

export const DELETE = gql`
    mutation ClientDelete($input: [String]) {
        clientDelete(input: $input) {
            total
        }
    }
`;

export const REMOVE_USER = gql`
    mutation RemoveClientUser($user_id: ID!, $client_id: ID!) {
        removeClientUser(user_id: $user_id, client_id: $client_id) {
            success
        }
    }
`;
