import { Flex, Grid, Stack, TabPanel } from '@chakra-ui/react';
import React from 'react';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { Field } from '../../../components/Form';

const SocialsTab = () => {
  return (
    <FormSection title='Restaurant Socials'>
      <FormSectionInner columns={1}>
        <Field
          component='text'
          name='socials.facebook'
          placeholder='Facebook Link'
          width='500px'
        />
        <Field
          component='text'
          name='socials.instagram'
          placeholder='Instagram Link'
          width='500px'
        />
      </FormSectionInner>
      <FormSectionInner columns={1}>
        <Field
          component='text'
          name='socials.twitter'
          placeholder='Twitter Link'
          width='500px'
        />
        <Field
          component='text'
          name='socials.tiktok'
          placeholder='Tiktok Link'
          width='500px'
        />
      </FormSectionInner>
    </FormSection>
  );
};

export default SocialsTab;
