import { Flex, Grid, Stack, TabPanel, Text } from '@chakra-ui/react';
import React from 'react';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { Field } from '../../../components/Form';
import { useWatch } from 'react-hook-form';
import { FaRegImage } from 'react-icons/fa';
import CarouselComponent from '../../../components/common/Carousel';

const GeneralTab = () => {
  const address = useWatch({ name: 'location.googleplaces.formatted_address' });
  const photos = useWatch({ name: 'photos' });
  const videos = useWatch({ name: 'videos' });
  const review = useWatch({ name: 'review' });
  const reviewOptions = [
    { label: 'Hot Stuff', _id: 'Hot Stuff 🔥🔥🔥' },
    { label: "Smokin' Hot", _id: "Smokin' Hot 🔥🔥🔥🔥" },
    { label: 'On Fire', _id: 'On Fire 🔥🔥🔥🔥🔥' },
    { label: 'Coming Soon', _id: 'Coming Soon' },
  ];


  return (
    <Grid
      templateColumns={{ base: '1fr' }}
      w='100%'
      maxWidth='1000px'
      gap='25px'
    >
      <Stack spacing='20px' w='100%'>
        <FormSection title='Restaurant Details'>
          <FormSectionInner columns={2}>
            <Field
              component='text'
              name='name'
              placeholder='Name'
              rules={{ required: true }}
              width='700px'
            />
          </FormSectionInner>
          <FormSectionInner columns={2}>
            <Field
              component='textarea'
              name='description'
              placeholder='Description'
              width='900px'
              isFullWidth
            />
          </FormSectionInner>
          <FormSectionInner columns={2}>
            <Field
              component='text'
              name='email'
              placeholder='Email Address'
              rules={{ required: true }}
            />
            <Field
              component='text'
              name='contact_number'
              placeholder='Contact Number'
              rules={{ required: true }}
            />
          </FormSectionInner>{' '}
          <FormSectionInner columns={1}>
            <Text fontWeight='bold' color='#1a202c' mt='1em'>
              Address
            </Text>
            <Text>{address}</Text>
          </FormSectionInner>
        </FormSection>

        {photos?.length > 0 ? (
          <Flex direction='column' mt='1em' w='100%'>
            <Flex align='center' gap={2}>
              <FaRegImage />
              <Text fontSize={'0.8em'} mb='0.3em'>
                {`${photos.length}`} Photo(s)
              </Text>
            </Flex>

            <CarouselComponent data={photos} />
          </Flex>
        ) : (
          <Flex align='center' mt='1em' gap={2}>
            <FaRegImage />
            <Text fontSize={'0.8em'}>No Images</Text>
          </Flex>
        )}

        {videos?.length > 0 ? (
          <Flex direction='column' mt='1em' w='100%'>
            <Flex align='center' gap={2}>
              <FaRegImage />
              <Text fontSize={'0.8em'} mb='0.3em'>
                {`${videos.length}`} Video(s)
              </Text>
            </Flex>

            <Flex direction='row' gap={10}>
              {videos?.map((reel) => {
                if (reel.iFrameUrl) {
                  return (
                    // eslint-disable-next-line jsx-a11y/iframe-has-title
                    <iframe
                      key={reel._id}
                      style={{
                        borderRadius: '20px',
                      }}
                      src={`${reel?.iFrameUrl}`}
                      allow='encrypted-media'
                      allowFullScreen
                      width='600'
                      height='400'
                      loading='lazy'
                    />
                  );
                }
              })}
            </Flex>
          </Flex>
        ) : (
          <Flex align='center' mt='1em' gap={2}>
            <FaRegImage />
            <Text fontSize={'0.8em'}>No Videos</Text>
          </Flex>
        )}
      </Stack>
      <Stack spacing='20px' w='100%'>
        <FormSection title='Review Score'>
          <FormSectionInner columns={1}>
            <Text display={"flex"} py="10px" fontSize={"1.5rem"}>
Current Rating - {review?._id ? review?._id : "Not Yet Rated" }

            </Text>
            
            <Field
              name={'review'}
              component='select'
              placeholder={'Select Review'}
              labelKey={'label'}
              valueKey={'_id'}
              defaultValue={review ? {label:review?._id,_id:review?._id} :null}
              options={reviewOptions}
            />
          </FormSectionInner>
        </FormSection>
      </Stack>
    </Grid>
  );
};

export default GeneralTab;
