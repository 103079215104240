import { Flex } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Field } from "../../components/Form";
import Controls from "./Controls";
import { FETCH_ALL } from "../../containers/Locations/graphql";
import { useSchemaQuery } from "../../react-apollo-introspection";
import { useFormContext } from "react-hook-form";
const AddFeaturedLocations = ({ field, index, name }) => {
  const [fetchPages, { data, loading, error }] = useSchemaQuery(
    "staticpages_List"
  );

  const { getValues } = useFormContext();
  useEffect(() => {
    if (name) {
      fetchPages({
        variables: {
          perPage: 999,
          filter: {
            OR: getValues(`${name}[${index}].featured`),
          },
        },
        fetchPolicy: "network-only",
      });
    }
  }, [name, index]);

  return (
    <Flex
      w="100%"
      direction="column"
      align="flex-start"
      rounded="5px"
      bg="white"
      p="15px"
      mb="15px"
    >
      <Controls index={index} />
      <Flex direction="column" w="100%">
        <Field
          name={`${name}[${index}].title`}
          component="text"
          placeholder="Heading"
          label=" Heading"
        />
        <Field
          name={`${name}[${index}].featured`}
          component="select"
          placeholder="Select Page(s)"
          labelKey={"title"}
          valueKey="_id"
          options={data?.staticpages_List?.items}
          multiSelect
          isMulti
          onInputChange={(e) => {
            fetchPages({
              variables: {
                filter: {
                  title: e,
                },
              },
              fetchPolicy: "network-only",
            });
          }}
          isSearchable
        />
      </Flex>
    </Flex>
  );
};

export default AddFeaturedLocations;
