/* eslint-disable */
const links = [
  {
    group: "Business Users",
    link: "/business-users",
    items: [],
    permissions: ["view-business"],
  },
  {
    group: "Explorer Users",
    link: "/explorer-users",
    items: [],
    permissions: ["All"],
  },
  {
    group: "Places",
    link: "/places",
    items: [],
    permissions: ["All"],
  },
  {
    group: "Locations",
    link: "/locations",
    items: [],
    permissions: ["All"],
  },
  {
    group: "Pricing",
    link: "/pricing",
    items: [],
    permissions: ["All"],
  },
  {
    group: "Blogs",

    items: [
      {
        title: "Blogs",
        href: "/blogs",
      },
      {
        title: "Blog Categories",
        href: "/blog-types",
      },
    ],
    permissions: ["All"],
  },

  {
    group: "Notifications",
    link: "/notifications",
    items: [],
    permissions: ["All"],
  },
  {
    group: "Reported",
    link: "/reported",
    items: [],
    permissions: ["All"],
  },

  {
    group: "Static Pages",

    items: [
      {
        title: "Pages",
        href: "/pages",
      },
      {
        title: "Location Pages",
        href: "/location-pages",
      },
    ],
    permissions: ["All"],
  },
  {
    group: "Notes",
    link: "/notes",
    items: [],
    permissions: ["All"],
  },
  {
    group: "Settings",
    items: [
      {
        title: "Admin Users",
        href: "/administrators",
      },
    
    ],
    permissions: ["Root"],
  },
];

export default links;
