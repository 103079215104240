import React from "react";
import List from "./List";
import Entry from ".";

const modelName = "Pricing";
const modelNameSingular = "Price";

export const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, "-").toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  canFilter: true,
  canSelect: true,
  accessor: "_id",
};

const routes = [
  {
    path: `/${setup.model}`,
    element: React.createElement(List, { title: setup.modelName }),
    index: true,
  },
  {
    path: `/${setup.model}/:type`,
    element: React.createElement(Entry, {
      title: `${setup.singular}`,
    }),
  },
  {
    path: `/${setup.model}/:type/:id`,
    element: React.createElement(Entry, {
      title: `${setup.singular}`,
    }),
  },
];

export default routes;
