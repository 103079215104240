import { Button } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { gql, useLazyQuery } from '@apollo/client';
const EMAIL_FORGOT = gql`
  query Query($email: String!, $postmark: postmarkValidationInput) {
    businessuser_ForgotPassword(email: $email, postmark: $postmark)
  }
`;

const ResetPassword = () => {
  const { getValues } = useFormContext();
  const email = getValues('email');
  const [resetPassword, { data, loading, error }] = useLazyQuery(EMAIL_FORGOT);

  return (
    <Button
      onClick={() =>
        resetPassword({
          variables: {
            email: email,
            postmark: {
              callbackUrl:
                'https://eatsleepdrinkuk.com/reset-password/business',
              templateAlias: 'resetPassword',
            },
          },
        })
      }
    >
      Send Reset Password Link
    </Button>
  );
};

export default ResetPassword;
