import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Button,
  Input,
  Box,
  Text,
} from "@chakra-ui/react";
import { FcCheckmark } from "react-icons/fc";
import {
  useSchemaMutation,
  useSchemaQuery,
} from "../../../react-apollo-introspection";
import { TbTrashXFilled } from "react-icons/tb";

const CreateCustomParentModal = ({
  newSearch,
  defaultOptions,
  loadingSearch,
  fetchedData,
  currentParent,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customName, setCustomName] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [updateGooglePlace, { data, error }] = useSchemaMutation(
    "googleplaces_UpdateById"
  );

  const [addressVars, setAddressVars] = useState(null);

  // useEffect(() => {
  //   if (address_components) {
  //     // const _postal_town = address_components.find((el) =>
  //     //   el.types.includes('postal_town')
  //     // );
  //     const _postal_town = address_components[0];
  //     setAddressVars(_postal_town);
  //   }

  //   // long_name, short_name, types;
  // }, [address_components]);

  const handleUpdateGooglePlace = (remove) => {
    if (remove) {
      console.log("HERE FIRED1111");

      updateGooglePlace({
        variables: {
          record: {
            custom: {},
          },
          _id: fetchedData?.locations_FindById?.googleplaces?._id,
        },
      });
    }

    if (!remove && selectedPlace && fetchedData) {
      updateGooglePlace({
        variables: {
          record: {
            custom: {
              address_components: [
                {
                  long_name: selectedPlace?.description,
                  short_name: selectedPlace?.description,
                  types: selectedPlace?.types,
                },
              ],
            },
          },
          _id: fetchedData?.locations_FindById?.googleplaces?._id,
        },
      });
      onClose();
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     refresh();
  //     onClose();
  //   }
  // }, [data]);

  return (
    <>
      {currentParent ? (
        <Flex align="center" gap={3}>
          <Text as="storng">{currentParent}</Text>
          <Text
            as="a"
            variant="outline"
            onClick={() => onOpen()}
            // textDecoration={'underline'}
            color="blue.600"
            ml="1ch"
            cursor={"pointer"}
          >
            (change)
          </Text>
          <Flex
            align="center"
            onClick={() => handleUpdateGooglePlace("delete")}
          >
            <TbTrashXFilled fontSize="1.2em" />
            <Text
              as="a"
              variant="outline"
              color="blue.600"
              ml="5px"
              cursor={"pointer"}
            >
              delete
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Button onClick={() => onOpen()}>Add custom parent location</Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder={"Enter place name"}
              onChange={(e) => {
                newSearch(e.target.value);
              }}
            />
            {defaultOptions.map((option, i) => (
              <Flex w="100%" borderBottom="1px solid #e2e8f0">
                <Flex
                  key={`search-opt-${i}`}
                  background={
                    selectedPlace?.google_place_id === option?.google_place_id
                      ? "green.200"
                      : "white"
                  }
                  justifyContent={"start"}
                  alignItems={"center"}
                  padding="10px"
                  w="100%"
                  cursor="pointer"
                  _hover={{
                    background: "rgba(0,0,0,0.04);",
                    color: "teal.500",
                  }}
                  onClick={() => {
                    setSelectedPlace(option);
                  }}
                >
                  {selectedPlace?.place_id === option?.google_place_id && (
                    <FcCheckmark style={{ marginRight: "5px" }} />
                  )}
                  <Text color="black.200">{option.description}</Text>
                </Flex>
              </Flex>
            ))}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleUpdateGooglePlace()}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCustomParentModal;
