import { useLazyQuery } from "@apollo/client";
import {
  Button,
  Flex,
  Grid,
  Stack,
  Input,
  Text,
  Box,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useCallback, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Field } from "../../../components/Form";
import { FormSection, FormSectionInner } from "../../../components/FormSection";
import {
  useSchemaQuery,
  useSchemaMutation,
} from "../../../react-apollo-introspection";
import CustomLocality from "./CustomLocality";
import debounce from "lodash.debounce";
import CustomParent from "./CustomParent";

const Form = ({ fetchData }) => {
  const { type } = useParams();
  const [staticPageSearch, setStaticPageSearch] = useState(null);
  const landingPageExists = useWatch({ name: "landing.exists" });
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const locationType = fetchData?.locations_FindById?.googleplaces?.types || [];

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [staticPageData, { data, loading, error }] = useSchemaQuery(
    "staticpages_List"
  );

  const [create, { data: createData, error: createError }] = useSchemaMutation(
    "locations_CreateOne"
  );

  const [fetchGoogleLocations, { data: googleLocationData }] = useSchemaQuery(
    "googleplacesautocomplete_Get"
  );

  const [
    fetchAutoCompleteLocations,
    { data: autoCompleteLocationData },
  ] = useSchemaQuery("locations_autocomplete");

  const toast = useToast();
  useEffect(() => {
    staticPageData({
      variables: {
        filter: {
          type: "location",
          title: staticPageSearch,
        },
        perPage: 500,
      },
    });
  }, [staticPageSearch]);

  const newSearchOptions = useCallback(
    debounce((inputValue, callback) => {
      if (!inputValue) {
        return callback([]);
      }
      return newSearch(inputValue, callback);
    }, 2000),
    []
  );
  const newOnChange = async (option) => {
    console.log(option, "option");
    //get place details from BE
    setSelectedLocation(option);
    // return setGeocodeResults(res.data);
    // });
    // return axios
    //   .get(`https://api.getAddress.io/get/${option}?api-key=${apiKeyAddress}`)
    //   .then((res) => {
    //     console.log(res, '!!! res details onsubmit');
    //     // newUpdateFullAddress(res.data, option);
    //     // newUpdateMapPin(res.data);

    //     setSelectedLocation(res?.data);
    //     // return setGeocodeResults(res.data);
    //   });
  };
  const newSearch = (inputValue, callback) => {
    console.log(
      inputValue,
      "Cannot read properties of undefined (reading 'then')"
    );

    if (type === "create") {
      fetchGoogleLocations({
        variables: {
          input: inputValue,
          // types: ['postal_town', 'park', 'point_of_interest'],
        },
      });
    } else {
      fetchAutoCompleteLocations({
        variables: {
          input: inputValue,
        },
      });
    }
    // return axios.get(
    //   `https://api.getAddress.io/autocomplete/${inputValue}?api-key=${apiKeyAddress}`
    // );
  };

  useEffect(() => {
    console.log(googleLocationData, "!!!!!googleLocationData", defaultOptions);
    if (googleLocationData) {
      setDefaultOptions(
        googleLocationData?.googleplacesautocomplete_Get?.predictions
      );
      setLoadingSearch(false);
    } else if (autoCompleteLocationData) {
      setDefaultOptions(
        autoCompleteLocationData?.locations_autocomplete?.predictions
      );
      setLoadingSearch(false);
    }
  }, [googleLocationData, autoCompleteLocationData]);

  const handleCreateLocation = () => {
    create({
      variables: {
        googleplaces: {
          place_id: selectedLocation?.place_id,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      window.location.href = "/locations";
    }
  }, [createData]);

  useEffect(() => {
    if (createError) {
      toast({
        status: "error",
        title: createError.message,
        position: "top-right",
      });
    }
  }, [createError]);
  const { getValues } = useFormContext();

  const isCustomLocality = locationType.find((el) => el === "custom_locality");

  return (
    <Flex direction={"column"} w="100%" maxWidth="1000px">
      {type === "create" ? (
        <Grid
          templateColumns={{ base: "1fr" }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Input
            placeholder={"Search location to creates"}
            onChange={(option) => {
              setLoadingSearch(true);

              newSearchOptions(option.target.value);
            }}
          />
          <Box mb="20px">
            {loadingSearch ? (
              <Spinner />
            ) : (
              <>
                {defaultOptions.length === 0 && (
                  <Text>
                    No results found, try again or create a parent region
                    instead
                  </Text>
                )}
                {defaultOptions.map((option, i) => (
                  <Flex w="100%" borderBottom="1px solid #e2e8f0">
                    <Box
                      key={`search-opt-${i}`}
                      background={"white"}
                      padding="10px"
                      w="100%"
                      cursor="pointer"
                      _hover={{
                        background: "rgba(0,0,0,0.04);",
                        color: "teal.500",
                      }}
                      onClick={() => {
                        newOnChange(option);
                      }}
                    >
                      <Text color="black.200">{option.description}</Text>
                    </Box>
                  </Flex>
                ))}
              </>
            )}
          </Box>

          {selectedLocation && (
            <Stack w="100%" mb="20px">
              <FormSection title={`${selectedLocation.description}`}>
                <Flex direction="column" background={"white"} px="22px">
                  <Text as="b">{selectedLocation?.locality}</Text>
                  <Text as="b">{selectedLocation?.town_or_city}</Text>
                  <Text as="b">{selectedLocation?.country}</Text>
                </Flex>
              </FormSection>
              <Button
                colorScheme="green"
                onClick={() => handleCreateLocation()}
              >
                Create
              </Button>
              {createError?.message && createError.message}
            </Stack>
          )}
        </Grid>
      ) : (
        <Grid
          templateColumns={{ base: "1fr" }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          {/* <AsyncSelect
        value={''}
        defaultOptions={defaultOptions}
        loadOptions={newSearchOptions}
        placeholder='Type to search for addresses...'
        onChange={(option) => {
          newOnChange(option.value);
        }}
        noOptionsMessage={({ inputValue }) =>
          inputValue
            ? `No option for "${inputValue}"`
            : `Type postcode or anything...`
        }
      /> */}
          <Stack spacing="20px" w="100%">
            <FormSection
              title={getValues("googleplaces.name")}
              withButton={
                landingPageExists && (
                  <Button
                    onClick={() =>
                      window.open(
                        `/location-pages/edit/${getValues(
                          "landing.staticpage._id"
                        )}`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    Go To Static Page
                  </Button>
                )
              }
            >
              {/* <Field
                component="text"
                name="googleplaces.name"
                placeholder="Google Name"
                help="requires a static page linked to this location"
              /> */}
              <FormSectionInner columns={2}>
                <Field
                  component="switch"
                  name="landing.exists"
                  placeholder="Has Landing Page?"
                  help="requires a static page linked to this location"
                />
                {landingPageExists && (
                  <>
                    {/* <Field
                component='text'
                name='landing.staticpage_id'
                placeholder='static page id'
                rules={{ required: landingPageExists ? true : false }}
              /> */}

                    <Field
                      component="select"
                      isSearchable
                      onInputChange={(e) => setStaticPageSearch(e)}
                      options={
                        data?.["staticpages_List"]?.items
                          ? data?.["staticpages_List"]?.items
                          : []
                      }
                      name="landing.staticpage"
                      placeholder="Select a static page to link to this location"
                      rules={{ required: landingPageExists ? true : false }}
                      labelKey="title"
                      valueKey="_id"
                    />
                  </>
                )}
              </FormSectionInner>
            </FormSection>
            <FormSection title={"Sub-Locations"}>
              <Stack>
                <FormSectionInner columns={1}>
                  {isCustomLocality ? (
                    <Text fontWeight="bold" color="red">
                      You cannot add a sub-location to a location that is a
                      custom locality
                    </Text>
                  ) : (
                    <CustomLocality fetchData={fetchData} />
                  )}
                  {/* <Field
                name={'featured'}
                component='select'
                placeholder='Select Location(s)'
                labelKey={'googleplaces.name'}
                valueKey='_id'
                options={locationData?.[FETCH_ALL]?.items}
                isMulti
              /> */}
                </FormSectionInner>
              </Stack>
            </FormSection>
            <FormSection title={"Custom Parents"}>
              <Stack>
                <FormSectionInner columns={1}>
                  <CustomParent
                    currentParent={
                      fetchData?.locations_FindById?.googleplaces?.custom
                        ?.address_components?.[0]?.long_name
                    }
                    defaultOptions={defaultOptions}
                    setDefaultOptions={setDefaultOptions}
                    loadingSearch={loadingSearch}
                    setLoadingSearch={setLoadingSearch}
                    fetchGoogleLocations={
                      type === "create"
                        ? fetchGoogleLocations
                        : fetchAutoCompleteLocations
                    }
                    fetchedData={fetchData}
                  />

                  {/* <Field
              name={'featured'}
              component='select'
              placeholder='Select Location(s)'
              labelKey={'googleplaces.name'}
              valueKey='_id'
              options={locationData?.[FETCH_ALL]?.items}
              isMulti
            /> */}
                </FormSectionInner>
              </Stack>
            </FormSection>
          </Stack>

          {/* <Stack>
            <FormSection title='Static Page Details'>
              <FormSectionInner columns={2}>
                <Field
                  component='text'
                  name='google.name'
                  placeholder='Name'
                  rules={{ required: true }}
                />
              </FormSectionInner>
            </FormSection>
          </Stack> */}
        </Grid>
      )}
    </Flex>
  );
};

export default Form;
