import { Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSchemaQuery } from '../../../react-apollo-introspection';

const PlaceById = ({ advertId }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(
    'places_FindOne',
    {
      variables: {
        filter: {
          adverts: [
            {
              _id: advertId,
            },
          ],
        },
      },
    }
  );

  useEffect(() => {
    fetchData();
  }, []);

  return <Text>{data?.places_FindOne?.name}</Text>;
};

export default PlaceById;
