import { Flex, Grid, Stack, TabPanel } from '@chakra-ui/react';
import React from 'react';
import { FormSection, FormSectionInner } from '../../../components/FormSection';
import { Field } from '../../../components/Form';

const LinksTab = () => {
  return (
    <FormSection title='Venue Links'>
      <FormSectionInner columns={1}>
        <Field
          component='text'
          name='website_url'
          placeholder='Website Url'
          width='900px'
        />
        <Field component='text' name='booking_url' placeholder='Booking Url' />
        <Field
          component='text'
          name='trip_advisor_url'
          placeholder='Trip Advisor Url'
        />
      </FormSectionInner>
    </FormSection>
  );
};

export default LinksTab;
