import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Tag,
  Button,
  Stack,
  useColorModeValue,
  Flex,
  Text,
  Switch,
} from '@chakra-ui/react';

import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import debounce from 'lodash.debounce';

import {
  DELETE,
  CUSTOM_FETCH_ALL_STRIPE,
  CUSTOM_FETCH_NO_STRIPE,
} from './graphql';
import { setup } from './setup';
import dayjs from 'dayjs';

const columns = [
  {
    Header: '',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant='link'>View</Button>
      </Link>
    ),
  },
  {
    Header: 'Customer',
    accessor: 'email',
    disableSortBy: true,
    Cell: ({ value, row }) => (
      <Flex direction={'column'}>
        <Flex direction={'row'}>
          <Text mr='1ch'>{row?.original?.firstName}</Text>
          <Text>{row?.original?.lastName}</Text>
        </Flex>
        <Text
          as='a'
          target='_blank'
          href={`mailto:${value}`}
          textColor={'blue.400'}
          fontWeight={'bold'}
        >
          {value}
        </Text>
      </Flex>
    ),
  },

  {
    Header: 'Email Validation',
    accessor: 'emailValidation',
    disableSortBy: false,
    Cell: ({ value }) => (
      <div>
        {value?.isValidated && (
          <Tag colorScheme='green' textAlign='center'>
            Validated
          </Tag>
        )}
      </div>
    ),
  },
  {
    Header: 'Registration Date',
    accessor: 'createdAt',
    disableSortBy: true,
    Cell: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
  },
  {
    Header: 'Subscription',
    accessor: 'stripe',
    disableSortBy: true,
    Cell: ({ row, value }) => (
      <Flex direction={'column'}>
        <Badge colorScheme='green' textAlign='center'>
          {value?.subscription?.product?.metadata?.sub_type}
        </Badge>

        <Text>{value?.subscription?.customer}</Text>
      </Flex>
    ),
  },
  {
    Header: 'Status',
    accessor: 'is_active',
    disableSortBy: false,
    Cell: ({ value }) =>
      value === true ? (
        <Badge colorScheme='green' width='9ch' textAlign='center'>
          ACTIVE
        </Badge>
      ) : (
        <Badge colorScheme='red' variant='solid' width='9ch' textAlign='center'>
          INACTIVE
        </Badge>
      ),
  },
  {
    Header: 'Suspended',
    accessor: 'isSuspended',
    disableSortBy: false,
    Cell: ({ value }) =>
      value === true ? (
        <Badge
          colorScheme='red'
          width='fit-content'
          variant='solid'
          textAlign='center'
        >
          Account Suspended
        </Badge>
      ) : null,
  },
];

const filters = [
  {
    value: 'last_name',
    type: 'text',
    label: 'Last Name',
    isOperator: false,
  },
];

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(
    CUSTOM_FETCH_NO_STRIPE
  );
  const [
    fetchStripeData,
    { loading: stripeLoading, error: stripeError, data: stripeData },
  ] = useLazyQuery(CUSTOM_FETCH_ALL_STRIPE);
  const [placeId, setPlaceId] = useState(null);

  const [loadStripe, setLoadStripe] = useState(false);
  const [showSuspended, setShowSuspended] = useState(false);
  const [showVerified, setShowVerified] = useState(false);
  const [searchString, setSearchString] = useState(null);

  const confirm = (e) => {
    setSearchString(e);
  };

  useEffect(() => {
    if (showVerified) {
      fetchData({
        variables: {
          filter: {
            isVerified: true,
          },

          // ...fetchFilters,
        },
      });
    } else if (showSuspended) {
      fetchData({
        variables: {
          filter: {
            isSuspended: true,
          },

          // ...fetchFilters,
        },
      });
    } else {
      fetchData({
        variables: {
          filter: {},
        },
      });
    }
  }, [showVerified, showSuspended]);

  useEffect(() => {
    if (placeId) {
      fetchData({
        variables: {
          filter: {
            places: [
              {
                _id: placeId,
              },
            ],
          },
        },
      });
    } else {
      fetchData({ variables: null });
    }
  }, [placeId]);

  useEffect(() => {
    if (searchString) {
      fetchData({
        variables: {
          filter: {
            OR: [
              {
                firstName: searchString,
              },
              {
                lastName: searchString,
              },
              {
                email: searchString,
              },
            ],
          },

          // ...fetchFilters,
        },
      });
    } else {
      fetchData({
        variables: {
          filter: {},

          // ...fetchFilters,
        },
      });
    }
  }, [searchString]);
  const _searchFunction = useCallback(debounce(confirm, 500), [searchString]);
  const [filterDrawer, setFilterDrawer] = useState([]);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack
          ml={'auto'}
          isInline
          spacing='15px'
          w='fit-content'
          alignItems={'center'}
        >
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}

          <Button onClick={() => setLoadStripe(!loadStripe)} w='100%'>
            {loadStripe ? 'Hide' : 'Show'} Subscription details
          </Button>
          <Flex direction={'row'} w='100%' whiteSpace={'nowrap'} gap='10px'>
            <Text>Show Suspended Users</Text>
            <Switch
              defaultValue={showSuspended}
              colorScheme='red'
              size='lg'
              isChecked={showSuspended}
              // value={showSuspended}
              onChange={() => {
                setShowSuspended(showSuspended === false ? true : false);

                setShowVerified(false);
              }}
            />
          </Flex>
          <Flex direction={'row'} w='100%' whiteSpace={'nowrap'} gap='10px'>
            <Text>Show Verified Users</Text>
            <Switch
              defaultValue={showVerified}
              colorScheme='green'
              // value={showVerified}
              isChecked={showVerified}
              size='lg'
              onChange={() => {
                setShowVerified(showVerified === false ? true : false);
                setShowSuspended(false);
              }}
            />
          </Flex>
        </Stack>
      </HeaderWithActions>
      <Box
        mt='25px'
        bg={useColorModeValue('white', 'gray.700')}
        shadow='base'
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w='100%'
      >
        <ListView
          searchForPlace={setPlaceId}
          setup={setup}
          fetchData={loadStripe ? fetchStripeData : fetchData}
          // setExternalSearch={_searchFunction}
          data={
            stripeData?.businessusers_List
              ? stripeData?.businessusers_List
              : data?.businessusers_List
          }
          loading={loading}
          // setSearchParam={setSearchString}
          error={error}
          gqlDelete={DELETE}
          setFilterDrawer={setFilterDrawer}
          columns={columns}
          filters={filters}
          customVariables={
            loadStripe && {
              custom: {
                stripe: {
                  sub_data: true,
                },
              },
            }
          }

          // searchString={searchString}
        />
      </Box>
    </Meta>
  );
};

export default List;
