import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tag,
  Button,
  Input,
  TagLabel,
} from '@chakra-ui/react';
import { IoIosAdd } from 'react-icons/io';
import { useSchemaMutation } from '../../../react-apollo-introspection';

const CreateCustomLocalityModal = ({
  placeID,
  address_components,
  refresh,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [customName, setCustomName] = useState(null);
  const [create, { data, error }] = useSchemaMutation('locations_CreateOne');

  const [addressVars, setAddressVars] = useState(null);

  useEffect(() => {
    if (address_components) {
      // const _postal_town = address_components.find((el) =>
      //   el.types.includes('postal_town')
      // );

      const _postal_town = address_components;
      setAddressVars(_postal_town);
    }

    // long_name, short_name, types;
  }, [address_components]);
  const handleCreateLocation = () => {
    if (customName && addressVars) {
      create({
        variables: {
          googleplaces: {
            place_id: placeID,
            custom: {
              address_components: [
                {
                  long_name: addressVars?.long_name,
                  short_name: addressVars?.short_name,
                  types: addressVars?.types,
                },
              ],
            },
            name: customName,
            types: ['custom_locality'],
          },
        },
      });
      // create({
      //   variables: {
      //     googleplaces: {
      //       place_id: placeID,
      //       custom: {
      //         address_components: addressVars,
      //       },
      //       name: customName,
      //       types: ['custom_locality'],
      //     },
      //   },
      // });
    }
  };

  useEffect(() => {
    if (data) {
      refresh();
      onClose();
    }
  }, [data]);

  return (
    <>
      <Tag
        size='lg'
        variant='outline'
        colorScheme='teal'
        m='5px'
        onClick={onOpen}
        cursor='pointer'
      >
        <IoIosAdd />

        <TagLabel> Add location</TagLabel>
      </Tag>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Custom Location</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder={'Enter place name'}
              onChange={(e) => {
                setCustomName(e.target.value);
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='green' onClick={() => handleCreateLocation()}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateCustomLocalityModal;
