import { Flex, Grid, Stack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
import { EDIT, FETCH, CREATE } from './graphql';

import { setup } from './setup';
import { FETCH_ALL } from '../Locations/graphql';

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);
  const [
    fetchLocations,
    { data: locationData, loading: locationLoading, error: locationError },
  ] = useSchemaQuery(FETCH_ALL);

  useEffect(() => {
    fetchLocations({ variables: { perPage: 50 } });
  }, []);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <Grid
          templateColumns={{ base: '1fr' }}
          w="100%"
          maxWidth="1000px"
          gap="25px"
        >
          <Stack spacing="20px" w="100%">
            <FormSection title="Blog Category">
              <FormSectionInner columns={2}>
                <Field
                  component="text"
                  name="description"
                  placeholder="Title"
                  rules={{ required: true }}
                />
                <Field component="text" name="slug" placeholder="Slug" />
              </FormSectionInner>

              <FormSectionInner columns={1}>
                <Field
                  component="richtext"
                  name="content.body"
                  placeholder="Enter opitonal blog category description"
                />
              </FormSectionInner>
              <FormSectionInner columns={2}>
                <Field
                  component="file"
                  name="image"
                  placeholder="Banner Image"
                />
              </FormSectionInner>

              <FormSectionInner columns={1}>
                <Field
                  imageType="thumbnail"
                  name="thumbnail"
                  placeholder="Thumbnail"
                  component="file"
                />
              </FormSectionInner>
            </FormSection>
          </Stack>
        </Grid>
      </EditView>
    </Meta>
  );
};

export default Index;
