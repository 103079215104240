import { Flex, Heading, Link, Text, toast, useToast } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import Meta from '../../components/Meta';
import {
  useSchemaMutation,
  useSchemaQuery,
} from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';

import { CREATE, EDIT, FETCH } from './graphql';

import { setup } from './setup';

const Index = ({ title }) => {
  const toast = useToast();
  const params = useParams();
  const [updateStatus, { loading: loadingEdit }] = useSchemaMutation(EDIT);
  const [fetchData, { data, loading, error }] = useSchemaQuery(
    'reporting_FindById'
  );
  const customStyles = {
    control: (base, state) => ({
      ...base,
      height: 30,
      minHeight: 30,
      minWidth: 50,
      boxShadow: 'none',
    }),
    menu: (base) => ({
      ...base,
      minWidth: 200,
    }),
    option: (base) => ({
      ...base,
      fontSize: 12,
    }),

    input: (base, state) => ({
      ...base,
      margin: 0,
      minWidth: state.selectProps.minWidth ? state.selectProps.minWidth : 30,
    }),
    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      minWidth: '300px',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 0,
      paddingBottom: 0,
    }),
    placeholder: (base, state) => ({
      ...base,
      fontSize: 12,
      color: state.selectProps.hasBg ? '#2b6da3' : 'rgba(0,0,0,0.5)',
    }),
    singleValue: (base, state) => ({
      ...base,
      fontSize: 12,
      fontWeight:
        state.selectProps.hasBg || state.selectProps.isOperator
          ? 'bold'
          : 'normal',
      backgroundColor: state.selectProps.isOperator ? '#dfeaff' : 'white',
      color: state.selectProps.isOperator ? '#2563d6' : 'black',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: state.selectProps.isOperator ? '#467ee6' : 'white',
      paddingLeft: state.selectProps.isOperator ? '4px' : '0px',
      paddingRight: state.selectProps.isOperator ? '4px' : '0px',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      '*': {
        padding: 0,
      },
    }),
    multiValueLabel: (base) => ({
      ...base,
      fontSize: 10,
      fontWeight: '500',
      color: 'white',
      backgroundColor: '#366DDD',
    }),
  };

  const [
    fetchPlaceData,
    { loading: placeLoading, error: placeError, data: placeData },
  ] = useSchemaQuery('places_FindOne', {
    variables: {
      filter: {
        adverts: [
          {
            _id: data?.reporting_FindById.advertId,
          },
        ],
      },
    },
  });
  const [
    fetchAdvertData,
    { loading: advertLoading, error: advertError, data: advertData },
  ] = useSchemaQuery('adverts_FindById', {
    variables: {
      _id: data?.reporting_FindById.advertId,
    },
  });

  useEffect(() => {
    fetchAdvertData();
    fetchPlaceData();
  }, []);

  const options = [
    {
      label: 'Created',
      value: 'created',
    },
    { label: 'Read', value: 'read' },
    { label: 'Come Back', value: 'come back' },
  ];

  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  const handleChange = (e) => {
    updateStatus({
      variables: {
        _id: params?.id,
        record: {
          status: e.value,
        },
      },
      onError() {
        toast({
          status: 'error',
          title: 'Error Updating Report Status',
          position: 'bottom-right',
        });
      },
      onCompleted() {
        toast({
          status: 'success',
          title: 'Report Status has been updated',
          position: 'bottom-right',
        });
      },
    });
  };

  return (
    // <>hello</>
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
        editFields={['landing']}
      >
        <Flex w='80%' display={'flex'} flexDirection={'column'}>
          <Heading>Reported Message</Heading>

          <Flex
            display={'flex'}
            flexDirection={'column'}
            bg='white'
            rounded='8px'
            p='20px'
            mt='10px'
            justifyContent={'start'}
            gap='40px'
          >
            <Flex fontWeight={'800'} display={'flex'} flexDirection={'row'}>
              Message -{' '}
              <Text fontWeight={'500'} ml='2px'>
                {data?.reporting_FindById?.content}
              </Text>
            </Flex>
            <Flex display={'flex'} gap={'5px'} alignItems={'center'}>
              View Venue -
              <Link
                color={'linkedin.600'}
                href={`/places/edit/${placeData?.places_FindOne?._id}`}
              >
                {placeData?.places_FindOne?.name}
              </Link>
            </Flex>
            <Flex
              fontWeight={'800'}
              display={'flex'}
              alignItems={'start'}
              gap='20px'
              w='full'
              flexDirection={'column'}
            >
              Status
              <Select
                styles={customStyles}
                onChange={(e) => handleChange(e)}
                isFullWidth
                //  formatOptionLabel={(op) => op.createdAt}
                // value={defaultValue ? defaultValue : defaultOptions}
                // options={options}
                defaultValue={options.find(
                  (el) => el.value === data?.reporting_FindById?.status
                )}
                options={options}
                menuPlacement='auto'
              />
            </Flex>
          </Flex>
        </Flex>
      </EditView>
    </Meta>
  );
};

export default Index;
