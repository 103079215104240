import { useLazyQuery } from '@apollo/client';
import { Grid, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import EditView from '../../components/EditView';
import { Field } from '../../components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import Meta from '../../components/Meta';
import ClientUsers from './ClientUsers';
import { CREATE, EDIT, FETCH } from './graphql';

import { setup } from './setup';

const Index = ({ title }) => {
    const params = useParams();
    const [fetchData, { data, loading, error }] = useLazyQuery(FETCH);

    const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

    return (
        <Meta title={` ${type} ${title}`}>
            <EditView
                fetchData={fetchData}
                loading={loading}
                error={error}
                data={data}
                setup={setup}
                gqlFetch={FETCH}
                gqlEdit={EDIT}
                gqlCreate={CREATE}
                ignoreValues={['users']}
            >
                <Grid
                    templateColumns={{ base: '1fr' }}
                    w='100%'
                    maxWidth='1000px'
                    gap='25px'
                >
                    <Stack spacing='20px' w='100%'>
                        <FormSection title='Client Details'>
                            <FormSectionInner columns={2}>
                                <Field
                                    component='text'
                                    name='title'
                                    placeholder='Title'
                                    rules={{ required: true }}
                                />
                            </FormSectionInner>
                            <FormSectionInner columns={2}>
                                <Field
                                    component='file'
                                    max={1}
                                    name='logo'
                                    placeholder='Client Logo'
                                />
                            </FormSectionInner>
                        </FormSection>
                    </Stack>
                    <CreateUserFields />
                    <ClientUsers />
                </Grid>
            </EditView>
        </Meta>
    );
};

const CreateUserFields = () => {
    const { type } = useParams();

    return type === 'create' ? (
        <Stack spacing='20px' w='100%'>
            <FormSection title='Master User'>
                <FormSectionInner
                    columns={2}
                    description={
                        <>
                            <Text>
                                This will be the master user for this client.
                            </Text>{' '}
                            <Text as='span' fontWeight='bold'>
                                When saved, an invitation email will be sent to
                                this user to setup their account.
                            </Text>
                        </>
                    }
                >
                    <Field
                        component='text'
                        name='first_name'
                        placeholder='First Name'
                        rules={{ required: true }}
                    />
                    <Field
                        component='text'
                        name='last_name'
                        placeholder='Last Name'
                        rules={{ required: true }}
                    />
                    <Field
                        component='text'
                        name='email'
                        placeholder='Email Address'
                        rules={{ required: true }}
                    />
                </FormSectionInner>
            </FormSection>
        </Stack>
    ) : null;
};

export default Index;
