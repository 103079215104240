export const FETCH_ALL = "notes_List";

export const FETCH = "notes_FindById";

export const CREATE = "notes_CreateOne";

export const EDIT = "notes_UpdateById";

export const DELETE = "notes_RemoveManyByIds";


