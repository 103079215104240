import { Box } from '@chakra-ui/react';
import * as React from 'react';

import { Card } from '../../components/FormCard';
import LayoutWithLogo from '../../components/LayoutWithLogo';

import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPassword = () => (
  <LayoutWithLogo subtitle='Reset password'>
    <Box maxWidth='lg' mx='auto' w='100%'>
      <Card w='100%'>
        <ResetPasswordForm />
      </Card>
    </Box>
  </LayoutWithLogo>
);
