import React from 'react';
import { Flex, useDisclosure, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import SelectModal from './SelectModal';
import TextWithHeading from './TextWithHeading';
import { createContext } from 'react';
import TextWithImage from './TextWithImage';
import AddBlogLinks from './AddBlogLinks';
import AddGalleryImages from './AddGalleryImages';
import AddGalleryImagesRow from './AddGalleryImagesRow';
import AddFeaturedLocations from './AddFeaturedLocations';
import DownloadAppBlock from './DowloadAppBlock';
import GetTheApp from './GetTheApp';
import StayInTheLoop from './StayInTheLoop';

export const PageEditorContext = createContext();

const PageEditor = ({ value, onChange, name, blog, location, gallery }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeIndex, setActiveIndex] = useState();
  const { fields, insert, remove } = useFieldArray({
    name: `${name}`,
  });

  const addBlock = (type) => {
    onClose();
    if (type === 'textWithHeading') {
      insert(activeIndex, {
        type: type,
        heading: '',
        text: '',
      });
    }
    if (type === 'appDownloadCTA') {
      insert(activeIndex, {
        type: type,
        align: 'left',
        heading: '',
        text: '',
        image: null,
      });
    }
    if (type === 'getTheApp') {
      insert(activeIndex, {
        type: type,
        align: 'left',
        heading: '',
        text: '',
        image: null,
      });
    }
    if (type === 'stayInTheLoop') {
      insert(activeIndex, {
        type: type,
        align: 'left',
        heading: '',
        text: '',
        image: null,
      });
    }

    if (type === 'textImageLeft') {
      insert(activeIndex, {
        type: type,
        align: 'left',
        heading: '',
        text: '',
        image: null,
      });
    }
    if (type === 'textImageRight') {
      insert(activeIndex, {
        type: type,
        align: 'right',
        heading: '',
        text: '',
        image: null,
      });
    }
    if (type === 'addBlogLinks') {
      insert(activeIndex, {
        type: type,
        blogs: [],
      });
    }
    if (type === 'addGalleryImages') {
      insert(activeIndex, {
        type: type,
        gallery: [],
      });
    }
    if (type === 'AddGalleryImagesRow') {
      insert(activeIndex, {
        type: type,
        gallery: [],
      });
    }
    if (type === 'addFeaturedLocations') {
      insert(activeIndex, {
        type: type,
        featured: [],
      });
    }
  };

  return (
    <PageEditorContext.Provider
      value={{ onOpen, setActiveIndex, insert, remove }}
    >
      <Flex w='100%' direction='column'>
        {fields && fields.length === 0 && (
          <Button
            onClick={() => {
              setActiveIndex(0);
              onOpen();
            }}
          >
            Add New Block
          </Button>
        )}

        <Flex bg='gray.100' rounded='5px' p='10px' direction='column'>
          {fields &&
            fields.map((field, i) => {
              switch (field.type) {
                case 'textWithHeading':
                  return (
                    <TextWithHeading
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
                case 'appDownloadCTA':
                  return (
                    <DownloadAppBlock
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align='left'
                      name={name}
                    />
                  );
                case 'getTheApp':
                  return (
                    <GetTheApp
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align='left'
                      name={name}
                    />
                  );
                case 'stayInTheLoop':
                  return (
                    <StayInTheLoop
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align='left'
                      name={name}
                    />
                  );

                case 'textImageLeft':
                  return (
                    <TextWithImage
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align='left'
                      name={name}
                    />
                  );
                case 'textImageRight':
                  return (
                    <TextWithImage
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      align='right'
                      name={name}
                    />
                  );
                case 'addBlogLinks':
                  return (
                    <AddBlogLinks
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
                case 'addGalleryImages':
                  return (
                    <AddGalleryImages
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
                case 'AddGalleryImagesRow':
                  return (
                    <AddGalleryImagesRow
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
                case 'addFeaturedLocations':
                  return (
                    <AddFeaturedLocations
                      key={field.id}
                      field={field}
                      index={i}
                      setActiveIndex={setActiveIndex}
                      onOpen={onOpen}
                      name={name}
                    />
                  );
              }
            })}
        </Flex>
        <SelectModal
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          addBlock={addBlock}
          blog={blog}
          location={location}
          gallery={gallery}
        />
      </Flex>
    </PageEditorContext.Provider>
  );
};

export default PageEditor;
