import {
  Badge,
  Box,
  Button,
  Flex,
  Stack,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';

const columns = [
  {
    Header: 'Location',
    accessor: '_id',
    disableSortBy: true,
    Cell: ({ row }) => (
      <Flex gap={5} align='center'>
        {' '}
        <Flex alignItems={'center'}>
          <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant='link'>{row?.original?.googleplaces?.name} </Button>
          </Link>
          {row?.original?.googleplaces?.types?.includes('custom_locality') && (
            <Text ml='3px'>
              {row?.original?.googleplaces?.address_components?.[0]?.long_name}
            </Text>
          )}
        </Flex>
      </Flex>
    ),
  },

  {
    Header: 'Page',
    accessor: 'googleplaces.name',
    disableSortBy: false,
    Cell: ({ row }) => (
      <Flex>
        <Text
          _hover={{ transform: 'scale(1.1)', color: 'red' }}
          cursor='pointer'
          justify='center'
          onClick={() =>
            window.open(
              `/location-pages/edit/${row.original.landing.staticpage.value}`,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          {row.original.landing.exists === true && (
            <Button variant='link'>View</Button>
          )}
        </Text>
      </Flex>
    ),
  },

  {
    Header: 'Types',
    accessor: 'googleplaces.types',
    disableSortBy: false,
    Cell: ({ value }) => {
      return (
        <Flex gap={5}>
          {value?.map((el, i) => {
            return (
              <Tag
                size={'md'}
                key={`${el}-${i}`}
                variant='solid'
                colorScheme={value == 'custom_locality' ? 'teal' : 'blue'}
                textTransform={'capitalize'}
                fontWeight='bold'
              >
                <Text px='0.5em'>{el.replaceAll('_', ' ')}</Text>
              </Tag>
            );
          })}
        </Flex>
      );
    },
  },
];

const filters = [
  // {
  //   value: 'types',
  //   type: 'select',
  //   label: 'Type',
  //   options: ['postal_town'],
  //   isOperator: false,
  // },

  {
    component: 'text',
    label: 'Types',
    name: 'types',
    placeholder: 'Select Location Type',
    // options: [{ label: 'City', value: 'postal_town' }],
    // labelKey: 'label',
    // valueKey: 'value',
  },
  {
    component: 'date',
    label: 'Expiry Date',
    name: 'expiry_date',
    placeholder: 'Expiry Date',
  },
];

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(
    'locations_List'
  );

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml='auto' isInline spacing='15px'>
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt='25px'
        bg={useColorModeValue('white', 'gray.700')}
        shadow='base'
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w='100%'
      >
        <ListView
          locationFilter={true}
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          filters={filters}
        />
      </Box>
    </Meta>
  );
};

export default List;
