import React, { useEffect, useState } from 'react';
import { useSchemaQuery } from '../../../react-apollo-introspection';
import {
  Flex,
  Tag,
  TagLabel,
  TagCloseButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Tooltip,
  Button,
  Text,
} from '@chakra-ui/react';
import { useSchemaMutation } from '../../../react-apollo-introspection';

import CreateCustomParentModal from './CreateCustomParentModal';
const CustomParent = ({
  defaultOptions,
  setDefaultOptions,
  loadingSearch,
  setLoadingSearch,
  fetchGoogleLocations,
  fetchedData,
  currentParent,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentLocation, setCurrentLocation] = useState(null);
  const cancelRef = React.useRef();

  const newSearch = (inputValue, callback) => {
    console.log(inputValue, '!!!oinpa');
    fetchGoogleLocations({
      variables: {
        input: inputValue,
        // types: ['postal_town', 'park', 'point_of_interest'],
      },
    });
    // return axios.get(
    //   `https://api.getAddress.io/autocomplete/${inputValue}?api-key=${apiKeyAddress}`
    // );
  };

  useEffect(() => {
    if (currentLocation) {
      onOpen();
    }
  }, [currentLocation]);
  return (
    <Flex flexWrap="wrap">
      <CreateCustomParentModal
        currentParent={currentParent}
        newSearch={newSearch}
        defaultOptions={defaultOptions}
        loadingSearch={loadingSearch}
        fetchedData={fetchedData}
      />
    </Flex>
  );
};

export default CustomParent;
