import { gql } from '@apollo/client';

export const FETCH_ALL = 'places_List';

export const FETCH = 'places_FindById';

export const CREATE = 'places_CreateOne';

export const EDIT = 'places_UpdateById';

export const DELETE = 'places_RemoveManyByIds';

export const CUSTOM_FETCH_ALL = gql`
  query Places_List(
    $regexp: places_regexp_OTC
    $custom: custom_places_List_arg
    $sort: SortFindManyplacesInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManyplacesInput
  ) {
    places_List(
      regexp: $regexp
      custom: $custom
      sort: $sort
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
      items {
        _id
        name
        location {
          _id
          geoLocation {
            coordinates
          }
          googleplaces {
            address_components {
              long_name
              short_name
            }
            adr_address
            formatted_address
          }
        }
        tracking {
          liked {
            count
          }
          viewed {
            count
          }
        }
        createdAt
        categories {
          drink
          eat
          sleep
        }
      }
    }
  }
`;
