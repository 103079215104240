import { gql } from "@apollo/client";

export const FETCH_ALL = "businessusers_List";

export const FETCH = "businessusers_FindById";

export const CREATE = "businessuser_CreateOne";

export const EDIT = "businessusers_UpdateById";

export const DELETE = "businessusers_RemoveManyByIds";
export const DELETE_ONE = "businessusers_RemoveById";

export const CUSTOM_FETCH_NO_STRIPE = gql`
  query Businessusers_List(
    $page: Int
    $perPage: Int
    $sort: SortFindManybusinessusersInput
    $filter: FilterFindManybusinessusersInput
  ) {
    businessusers_List(
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      count
      items {
        firstName
        lastName
        email
        isSuspended
        places {
          categories {
            eat
            sleep
            drink
          }
          name
        }
        emailValidation {
          isValidated
        }
        _id
        updatedAt
        createdAt
        token
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export const CUSTOM_FETCH_ALL_STRIPE = gql`
  query Businessusers_List(
    $custom: custom_businessusers_List_argsInput
    $page: Int
    $perPage: Int
    $filter: FilterFindManybusinessusersInput
    $sort: SortFindManybusinessusersInput
  ) {
    businessusers_List(
      custom: $custom
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      items {
        firstName
        isSuspended
        lastName
        email
        stripe {
          customerId
          subscription {
            id
            customer
            status
            items {
              id
              price {
                id
              }
              quantity
            }
            metadata {
              eat
              sleep
              drink
            }
            product {
              id
              name
              active
              default_price
              description
              metadata {
                sub_type
              }
            }
          }
        }
        places {
          search_tags
          ideal_postcode_address
          input_address
          categories {
            eat
            sleep
            drink
          }
          name
          description
          email
          contact_number
          trip_advisor_url
          website_url
          booking_url
          adverts {
            categories {
              eat
              sleep
              drink
            }
            start_date
            end_date
            offer_name
            offer_terms
            enabled
            placeId
            type
            promotions
            explorer {
              unique_views {
                count
              }
            }
            tags {
              _id
              value
              category
            }
            _id
            updatedAt
            createdAt
            active
          }
          thegoodstuff {
            description
            categories {
              eat
              sleep
              drink
            }
            iconUrl
            _id
            updatedAt
            createdAt
          }
          menus
          visits {
            count
          }
          socials
          location {
            placeId
            tags {
              _id
              value
              category
            }
            geoLocation {
              type
              coordinates
            }
            landing {
              exists
              staticpage {
                _id
                title
                hero
                content
              }
            }
            featuredLocations {
              _id
            }
            _id
            updatedAt
            createdAt
            googleplaces {
              name
              locationId
              place_id
              address_components {
                long_name
                short_name
                types
                _id
              }
              adr_address
              business_status
              formatted_address
              formatted_phone_number
              geometry
              icon
              icon_background_color
              icon_mask_base_uri
              international_phone_number
              opening_hours
              permanently_closed
              photos
              plus_code
              price_level
              rating
              reference
              reviews
              scope
              types
              url
              user_ratings_total
              utc_offset
              vicinity
              website
              custom {
                address_components {
                  long_name
                  short_name
                  types
                  _id
                }
              }
              _id
              updatedAt
              createdAt
            }
            blogs {
              title
              subTitle
              content
              excerpt
              locations {
                _id
              }
              categories {
                description
                image {
                  imageUrl
                }
                thumbnail {
                  imageUrl
                }
                slug
                content
                _id
                updatedAt
                createdAt
              }
              seo

              slug
              _id
              updatedAt
              createdAt
              image {
                imageUrl
                placeId
                menu
                blogId
                adminuserId
                advertId
                _id
                updatedAt
                createdAt
                url
                Path
                ObjectName
                IsDirectory
                ghost
              }
            }
          }
          _id
          updatedAt
          createdAt
          videos {
            videoId
            placeId
            _id
            updatedAt
            createdAt
            directPlayUrl
            iFrameUrl
            mp4Url
          }
          photos {
            imageUrl
            placeId
            menu
            blogId
            adminuserId
            advertId
            _id
            updatedAt
            createdAt
            url
            Path
            ObjectName
            IsDirectory
            ghost
          }
          tracking {
            viewed {
              count
            }
            liked {
              count
            }
          }
        }
        isVerified
        emailValidation {
          isValidated
        }
        _id
        updatedAt
        createdAt
        token
      }
      count
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
