import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Flex, Grid, Stack, Text, Button } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import EditView from '../../components/EditView';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { authAtom } from '../../state/auth';
// import Form from "./Components/Form";
import { CREATE, EDIT, FETCH } from './graphql';

import { setup } from './setup';
import Form from './components/Form';
import { FormSection, FormSectionInner } from '../../components/FormSection';
import { Field } from '../../components/Form';

const SEARCH = gql`
  query Query($regexp: places_regexp_OTC, $perPage: Int) {
    places_List(regexp: $regexp, perPage: $perPage) {
      items {
        _id
        name
      }
    }
  }
`;

const Index = ({ title }) => {
  const params = useParams();
  const [fetchData, { data, loading, error }] = useSchemaQuery(FETCH);
  const { data: searchData, loading: loadingData, error: errorData } = useQuery(
    SEARCH,
    {
      variables: {
        perPage: 999,
      },
    }
  );

  const [auth, setAuth] = useRecoilState(authAtom);
  const type = params.type.charAt(0).toUpperCase() + params.type.slice(1);

  return (
    <Meta title={` ${type} ${title}`}>
      <EditView
        fetchData={fetchData}
        loading={loading}
        error={error}
        data={data}
        setup={setup}
        gqlFetch={FETCH}
        gqlEdit={EDIT}
        gqlCreate={CREATE}
      >
        <Flex direction='column' maxWidth='75%' w='100%'>
          <Grid
            templateColumns={{ base: '1fr' }}
            w='100%'
            maxWidth='1000px'
            gap='25px'
          >
            <Stack spacing='20px' w='100%'>
              <FormSection title='Notes Info'>
                <FormSectionInner columns={1}>
                  <Field
                    component='text'
                    name='title'
                    placeholder='Title'
                    rules={{ required: true }}
                  />
                </FormSectionInner>
                <FormSectionInner columns={1}>
                  <Flex flexDirection={'column'} gap='20px' fontSize='1rem'>
                    <Flex
                      justifyContent={'start'}
                      alignItems={'center'}
                      gap='10px'
                    >
                      Place Selected -
                      <Text fontWeight={'700'}>
                        {
                          searchData?.places_List?.items.find(
                            (item) => item._id === data?.[FETCH]?.placeId
                          )?.name
                        }
                      </Text>
                    </Flex>
                  </Flex>

                  <Field
                    name={`placeId`}
                    component='select'
                    options={searchData?.places_List?.items}
                    defaultValue={data?.[FETCH]?.placeId}
                    placeholder={
                      searchData?.places_List?.items.find(
                        (item) => item._id === data?.[FETCH]?.placeId
                      )
                        ? 'Change Place'
                        : 'Select Place'
                    }
                    labelKey='name'
                    valueKey='_id'
                    // saveFullObject={true}
                  />
                </FormSectionInner>

                <FormSectionInner columns={1}>
                  <Field
                    name={`content`}
                    component='repeater'
                    singleName='Note'
                    layout='vertical'
                    width='100%'
                    minWidth='100%'
                    isRepeater={true}
                    fieldArray={[
                      {
                        name: 'note_title',
                        component: 'text',
                        placeholder: 'Note Title',
                        label: 'Note Title',
                      },
                      {
                        name: 'note',
                        component: 'richtext',
                        placeholder: 'note',
                        label: 'Note',
                      },
                    ]}
                  />
                </FormSectionInner>
              </FormSection>
            </Stack>
          </Grid>
        </Flex>
      </EditView>
    </Meta>
  );
};

export default Index;
