import React, { useState, useEffect } from 'react';
import { Select, Input } from '@chakra-ui/react';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { tableAtom, tableFilterAtom } from './atoms';
import { useRecoilState, useRecoilValue } from 'recoil';

const CustomSearch = ({ filters }) => {
  const [googleplaces, setGoogleplaces] = useState(null);
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const [fetchTypes, { data, loading, error }] = useSchemaQuery(
    'googleplaces_getTypeOptions'
  );

  useEffect(() => {
    fetchTypes();
  }, []);
  useEffect(() => {
    if (googleplaces) {
      const googleplacesName = googleplaces;
      setTableState((old) => ({
        ...old,
        regexp: {
          googleplacesName,
        },
      }));
    }
  }, [googleplaces]);

  return (
    <>
      <Input
        placeholder='Search by name'
        onChange={(e) => {
          if (e.target.value === null) {
            setGoogleplaces(null);
          } else {
            setGoogleplaces(
              (old) =>
                // ...old,
                e.target.value
            );
          }
        }}
      />

      <Select
        onChange={(option) => {
          if (option.target.value === 'clear') {
            setGoogleplaces(null);
          } else {
            setGoogleplaces((old) => ({
              ...old,
              types: [option.target.value],
            }));
          }
        }}
      >
        <option value='clear'>Select one</option>
        {data?.googleplaces_getTypeOptions?.map((type) => (
          <option value={type}>{type}</option>
        ))}
      </Select>
    </>
  );
};

//
export default CustomSearch;
