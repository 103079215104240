import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React from 'react';
import GeneralTab from './Tabs/GeneralTab';
import SocialsTab from './Tabs/SocialsTab';
import AdvertTab from './Tabs/AdvertTab';
import LinksTab from './Tabs/LinksTab';

const TabView = ({ data }) => {
  return (
    <Flex align='start'>
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Adverts</Tab>
          <Tab>Links</Tab>
          <Tab>Socials</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <GeneralTab data={data} />
          </TabPanel>
          <TabPanel>
            <AdvertTab data={data} />
          </TabPanel>
          <TabPanel>
            <LinksTab data={data} />
          </TabPanel>
          <TabPanel>
            <SocialsTab data={data} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default TabView;
