import { get } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useFormContext, Controller } from 'react-hook-form';
import debounce from 'lodash.debounce';
import { useSchemaQuery } from '../react-apollo-introspection';
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9992,
  }),
};

const SelectField = (props) => {
  const {
    setActive,
    onChange,
    onBlur,
    asyncQuery,
    value,
    labelKey,
    valueKey,
    isAsync,
    options,
    selectValue,
    selectValueLabel,
    container,
    defaultValue,
    compositeLabelKey,
    name,
    defaultValues,
    saveFullObject,
    // isMulti,
    multiSelect,
    ...rest
  } = props;

  // const [searchResults, { data, loading, error }] = useSchemaQuery(asyncQuery);
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [fetchPages, { data, loading, error }] = useSchemaQuery(
    'staticpages_List'
  );
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loadedOptions, setLoadedOptions] = useState([]);
  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const watchField = watch();
  console.log(defaultOptions, '!!!!!!!!default');
  useEffect(() => {
    if (!multiSelect && get(watchField, name)?.__typename && firstPageLoad) {
      setFirstPageLoad(false);
      setDefaultOptions({
        value: get(watchField, name)[valueKey],
        label: get(watchField, name)[labelKey],
      });
    }

    if (multiSelect && firstPageLoad && options) {
      setFirstPageLoad(false);
      let _selectedOptions = [];
      let _selectedDefaultOptions = [];
      const defaultOptionFromBE = get(watchField, name);
      if (defaultOptionFromBE.length > 0) {
        defaultOptionFromBE?.forEach((option) => {
          _selectedOptions.push({ _id: option._id });

          const correctOption = options.find((el) => el._id === option._id);

          if (correctOption) {
            _selectedDefaultOptions.push({
              value: option[valueKey],
              label: correctOption[labelKey],
            });
          }
        });
      }

      // setDefaultOptions(_selectedDefaultOptions);

      setDefaultOptions(_selectedDefaultOptions);
      setValue(name, _selectedOptions);
    }

    // if (isMulti) {
    //if is a blog of location relationship
    if (get(watchField, name)?.[0]?.__typename) {
      let _selectedOptions = [];
      let _selectedDefaultOptions = [];
      watchField?.[name]?.forEach((option) => {
        // saveMultiToFormAndRender({{ _id: option._id }}, setValue, name, setDefaultOptions);

        _selectedOptions.push({ _id: option._id });

        // console.log(option[labelKey], 'from!!!!!!!!!!', option);

        _selectedDefaultOptions.push({
          label: compositeLabelKey
            ? get(option, labelKey) +
              ' (' +
              get(option, compositeLabelKey) +
              ')'
            : get(option, labelKey),
          value: option._id,
        });
      });

      setDefaultOptions(_selectedDefaultOptions);
      setValue(name, _selectedOptions);
    }

    // is if a content type that has been saved down
    if (
      get(watchField, name)?.[0]?.value &&
      firstPageLoad &&
      options?.length > 0
    ) {
      setFirstPageLoad(false);
      let _selectedDefaultOptions = [];

      //loop through all options
      get(watchField, name)?.forEach((option, i) => {
        //id is always id
        _selectedDefaultOptions.push({
          label: compositeLabelKey
            ? get(option, 'value.' + labelKey) +
              ' (' +
              get(option, 'value.' + compositeLabelKey) +
              ')'
            : get(option, 'value.' + labelKey),
          value: getOriginalOption(option._id, options),
        });
      });

      setDefaultOptions(_selectedDefaultOptions);
      // setValue(name, _selectedOptions);
      // } else {

      // }
    }
  }, [watchField, firstPageLoad, options]);

  const renderOptions = () => {
    const _options =
      options &&
      options.map((o) => {
        if (typeof o === 'object') {
          return {
            label: compositeLabelKey
              ? get(o, labelKey) + ' (' + get(o, compositeLabelKey) + ')'
              : get(o, labelKey),
            value: o[valueKey],
          };
        } else {
          return {
            label: o,
            value: o,
          };
        }
      });
    return _options;
  };

  const handleChange = (e) => {
    props?.multiSelect
      ? saveMultiToFormAndRender(
          e,
          setValue,
          name,
          setDefaultOptions,
          saveFullObject,
          options
        )
      : saveSingleToFormAndRender(
          e,
          setValue,
          name,
          setDefaultOptions,
          saveFullObject,
          options
        );
  };

  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      if (!inputValue || inputValue?.length < 3) {
        return callback([]);
      }

      changeSearch(inputValue, callback);
    }, 500),
    []
  );
  // const loadOptions = useCallback(

  // debounce((inputValue, callback) => {
  //   if (inputValue || inputValue?.length < 3) {
  //     return callback([]);
  //   }

  //   console.log("maxim is a terrlible manager", inputValue);
  //   changeSearch(inputValue, callback);

  //   // Promise.all([searchResult]).then((getValues) =>
  //   //   console.log(getValues, "<=====res")
  //   // );
  //   // console.log(searchResult, "<====== ben hill is not defined");
  // });
  const changeSearch = (inputValue, callback) => {
    console.log('dean smells of beans', inputValue);
    fetchPages({
      variables: {
        perPage: 100,
        filter: {
          title: inputValue,
        },
      },
      onCompleted: (res) => {
        console.log(res, 'what is res');
        setDefaultOptions(formatOptions(res?.staticpages_List?.items));

        callback(formatOptions(res?.staticpages_List?.items));
      },
    });
  };
  const formatOptions = (value) => {
    const _options = value.map((o) => {
      if (typeof o === 'object') {
        return {
          label: compositeLabelKey
            ? get(o, labelKey) + ' (' + get(o, compositeLabelKey) + ')'
            : get(o, labelKey),
          value: o[valueKey],
        };
      } else {
        return {
          label: o,
          value: o,
        };
      }
    });
    return _options;
  };

  return (
    <>
      {!isAsync ? (
        <Select
          styles={customStyles}
          onChange={(e) => handleChange(e)}
          isFullWidth
          onBlur={() => {
            onBlur && onBlur();
          }}
          //  formatOptionLabel={(op) => op.createdAt}
          value={defaultValue ? defaultValue : defaultOptions}
          // options={options}
          options={renderOptions()}
          menuPortalTarget={
            !container && typeof window !== 'undefined'
              ? document.body
              : container
          }
          menuPlacement='auto'
          defaultValue={defaultValue}
          {...rest}
        />
      ) : (
        <>
          <AsyncSelect
            cacheOptions
            key={'options?.length'}
            styles={customStyles}
            onChange={(e) => handleChange(e)}
            isFullWidth
            // onBlur={() => {
            //   onBlur && onBlur();
            // }}
            //  formatOptionLabel={(op) => op.createdAt}
            value={defaultValue ? defaultValue : defaultOptions}
            defaultOptions={defaultOptions}
            // options={options}
            loadOptions={loadOptions}
            // defaultOptions={renderOptions()}
            menuPortalTarget={
              !container && typeof window !== 'undefined'
                ? document.body
                : container
            }
            menuPlacement='auto'
            {...rest}
          />
        </>
      )}
    </>
  );
};

const getOriginalOption = (selected, options) => {
  console.log(selected, options, 'from getOriginalOption');

  const _selected = options.find((o) => o._id === selected);

  return _selected;
};
const saveMultiToFormAndRender = (
  value,
  setValue,
  name,
  setDefaultOptions,
  saveFullObject,
  options
) => {
  let _selectedOptions = [];
  let _selectedDefaultOptions = [];

  //clear it out
  setValue(name, []);

  value.forEach((option) => {
    _selectedDefaultOptions.push(option);
    saveFullObject
      ? _selectedOptions.push({
          _id: option?.value?._id ?? option.value,
          value: getOriginalOption(option?.value?._id ?? option.value, options),
        })
      : _selectedOptions.push({ _id: option.value });
    // _selectedOptions.push({ _id: option.value });
  });

  saveToForm(setValue, name, _selectedOptions);
  renderFormField(_selectedDefaultOptions, setDefaultOptions);
};

const saveSingleToFormAndRender = (
  value,
  setValue,
  name,
  setDefaultOptions,
  saveFullObject,
  options
) => {
  //clear it out
  setValue(name, []);

  const _value = saveFullObject
    ? {
        _id: value?._id ?? value.value,
        value: getOriginalOption(value.value, options),
      }
    : { _id: value.value };

  saveToForm(setValue, name, _value);
  renderFormField(value, setDefaultOptions);
};

const renderFormField = (_selectedDefaultOptions, setDefaultOptions) => {
  setDefaultOptions(_selectedDefaultOptions);
};
const saveToForm = (setValue, name, _selectedOptions) => {
  setValue(name, _selectedOptions);
};

export default SelectField;
