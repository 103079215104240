import {
  Badge,
  Box,
  Button,
  Flex,
  Stack,
  Tag,
  Text,
  MenuItem,
  useColorModeValue,
} from '@chakra-ui/react';

import React, { useCallback, useEffect, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { DELETE, FETCH_ALL } from './graphql';
import { setup } from './setup';
import PlaceById from './components/PlaceById';
import AdvertById from './components/AdvertById';
import PlaceAddressById from './components/PlaceAddressById';
import { debounce } from 'lodash';

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(FETCH_ALL);
  const [placeId, setPlaceId] = useState(null);
  const columns = [
    {
      Header: '.',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <Link to={`/${setup.model}/edit/${row?.original?._id}`}>
            <Button variant='link'>View Note</Button>
          </Link>
        </Flex>
      ),
    },

    {
      Header: 'Place',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <PlaceById placeId={row?.original?.placeId} />
        </Flex>
      ),
    },
    {
      Header: 'Note Title',
      accessor: 'title',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <Text>{row?.original?.title}</Text>
        </Flex>
      ),
    },
    {
      Header: 'Address',
      accessor: '',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align='center'>
          <PlaceAddressById placeId={row?.original?.placeId} />
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    if (placeId) {
      fetchData({
        variables: {
          filter: {
            placeId,
          },
        },
      });
    } else {
      fetchData({ variables: null });
    }
  }, [placeId]);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml='auto' isInline spacing='15px'>
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt='25px'
        bg={useColorModeValue('white', 'gray.700')}
        shadow='base'
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w='100%'
      >
        <ListView
          searchForPlace={setPlaceId}
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
        />
      </Box>
    </Meta>
  );
};

export default List;
