import { gql, useMutation } from '@apollo/client';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Field, Form } from '../../components/Form';
import GraphQLError from '../../components/GraphQLError';

const INVITE_USER = gql`
    mutation InviteUser(
        $client_id: ID!
        $first_name: String!
        $last_name: String!
        $email: String!
        $permission_role: String!
    ) {
        clientInviteUser(
            client_id: $client_id
            first_name: $first_name
            last_name: $last_name
            email: $email
            permission_role: $permission_role
        ) {
            success
        }
    }
`;

const NewUser = ({ user }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id } = useParams();
    const toast = useToast();
    const [inviteUser, { loading, error }] = useMutation(INVITE_USER);

    return (
        <>
            <Button
                size='sm'
                ml='auto'
                onClick={() => onOpen()}
                isDisabled={loading}
                isLoading={loading}
            >
                Invite New User
            </Button>
            {isOpen && (
                <Modal
                    containerRef={null}
                    isCentered
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalOverlay />
                    <ModalCloseButton />
                    <Form
                        onSubmit={(v) =>
                            inviteUser({
                                variables: { ...v, client_id: id },
                                refetchQueries: ['GetClient'],
                                onCompleted: () => {
                                    onClose();
                                    toast({
                                        status: 'success',
                                        description:
                                            'User has been invited successfully',
                                    });
                                },
                            })
                        }
                    >
                        <ModalContent>
                            <ModalHeader>Invite New User</ModalHeader>
                            <ModalBody>
                                <Field
                                    name='first_name'
                                    component='text'
                                    placeholder='First Name'
                                    rules={{ required: true }}
                                />
                                <Field
                                    name='last_name'
                                    component='text'
                                    placeholder='Last Name'
                                />
                                <Field
                                    name='email'
                                    component='text'
                                    placeholder='Email Address'
                                />
                                <Field
                                    name='permission_role'
                                    component='nativeSelect'
                                    placeholder='Permissions'
                                    options={['ADMIN', 'VIEW_ONLY']}
                                />
                                <GraphQLError error={error} />
                            </ModalBody>
                            <ModalFooter>
                                <SubmitButton />
                            </ModalFooter>
                        </ModalContent>
                    </Form>
                </Modal>
            )}
        </>
    );
};

const SubmitButton = () => {
    const { submit } = useFormContext();
    return (
        <Button onClick={() => submit()} colorScheme='green'>
            Invite User
        </Button>
    );
};

export default NewUser;
