import { Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSchemaQuery } from '../../../react-apollo-introspection';

const PlaceAddressById = ({ advertId, placeId }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(
    'places_FindOne'
  );

  useEffect(() => {
    if (placeId) {
      fetchData({
        variables: {
          filter: {
            _id: placeId,
          },
        },
      });
    } else if (advertId) {
      fetchData({
        variables: {
          filter: {
            adverts: [
              {
                _id: advertId,
              },
            ],
          },
        },
      });
    }
  }, []);
  console.log(data, 'data');
  return (
    <Text>
      {data?.places_FindOne?.location?.googleplaces.formatted_address}
    </Text>
  );
};

export default PlaceAddressById;
