import { useLazyQuery } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import React, { useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import HeaderWithActions from "../../components/HeaderWithActions";
import ListView from "../../components/ListView";
import Meta from "../../components/Meta";
import { useSchemaQuery } from "../../react-apollo-introspection";
import debounce from "lodash.debounce";
import { DELETE, FETCH_ALL, CUSTOM_FETCH_ALL } from "./graphql";
import { setup } from "./setup";
import { AiOutlineHeart, AiOutlineEye } from "react-icons/ai";
const columns = [
  {
    Header: "",
    accessor: "_id",
    disableSortBy: true,
    Cell: ({ row }) => (
      <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
        <Button variant="link">View</Button>
      </Link>
    ),
  },

  {
    Header: "Name",
    accessor: "name",
    disableSortBy: false,
    Cell: ({ value }) => <div>{value}</div>,
  },
  {
    Header: "Tracking",
    accessor: "tracking",
    disableSortBy: false,
    Cell: ({ value }) => (
      <Flex direction={"column"}>
        <Flex>
          <AiOutlineEye fontSize={"16px"} />
          <Text as="b">{value?.viewed?.count}</Text>
        </Flex>
        <Flex>
          <AiOutlineHeart fontSize={"16px"} />
          <Text as="b">{value?.liked?.count}</Text>
        </Flex>
      </Flex>
    ),
  },
  {
    Header: "Address",
    accessor: "location",
    disableSortBy: false,
    Cell: ({ value }) => <div> {value?.googleplaces?.formatted_address} </div>,
  },

  {
    Header: "Category",
    accessor: "categories",
    disableSortBy: false,
    Cell: ({ value, row }) => {
      console.log(row, "what is row");
      return (
        <Flex gap={5}>
          {value?.eat && (
            <Text
              fontWeight="bold"
              bg="#b52828"
              px="10px"
              py="0.1em"
              color="white"
              borderRadius="50px"
            >
              Eat
            </Text>
          )}
          {value?.sleep && (
            <Text
              fontWeight="bold"
              bg="#749079"
              px="10px"
              py="0.1em"
              color="white"
              borderRadius="50px"
            >
              Sleep
            </Text>
          )}
          {value?.drink && (
            <Text
              fontWeight="bold"
              bg="#6d2db7"
              px="10px"
              py="0.1em"
              color="white"
              borderRadius="50px"
            >
              Drink
            </Text>
          )}
        </Flex>
      );
    },
  },
];

const filters = [
  {
    value: "last_name",
    type: "text",
    label: "Last Name",
    isOperator: false,
  },
];

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useLazyQuery(CUSTOM_FETCH_ALL);
  const [searchString, setSearchString] = useState(null);

  const confirm = (e) => {
    setSearchString(e);
  };
  useEffect(() => {
    if (searchString) {
      fetchData({
        variables: {
          regexp: {
            name: searchString,
          },

          // ...fetchFilters,
        },
      });
    } else {
      fetchData({
        variables: {},
      });
    }
  }, [searchString]);

  const _searchFunction = useCallback(debounce(confirm, 500), [searchString]);

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue("white", "gray.700")}
        shadow="base"
        rounded={{
          base: "md",
          sm: "xl",
        }}
        w="100%"
      >
        <ListView
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
          // fetchFilters={{ filter: { stripe: { sub_type: "free" } } }}
          filters={filters}
          setExternalSearch={_searchFunction}
          ternalSearch={_searchFunction}
        />
      </Box>
    </Meta>
  );
};

export default List;
