import { Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSchemaQuery } from '../../../react-apollo-introspection';

const PlaceById = ({ advertId, placeId }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(
    'places_FindOne'
  );

  useEffect(() => {
    if (placeId) {
      fetchData({
        variables: {
          filter: {
            _id: placeId,
          },
        },
      });
    } else if (advertId) {
      fetchData({
        variables: {
          filter: {
            adverts: [
              {
                _id: advertId,
              },
            ],
          },
        },
      });
    }
  }, []);

  return <Text>{data?.places_FindOne?.name}</Text>;
};

export default PlaceById;
