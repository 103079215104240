export const FETCH_ALL = "notifications_List";

export const FETCH = "notifications_FindById";

export const CREATE = "notifications_CreateOne";

export const EDIT = "notifications_UpdateById";

export const DELETE = "notifications_RemoveManyByIds";

export const FIRE = "notifications_fire";
