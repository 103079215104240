import React from 'react';
import { Select } from '@chakra-ui/react';

const NativeSelectField = (props) => {
  const {
    onChange,
    onBlur,
    value,
    labelKey,
    valueKey,
    options,
    ...rest
  } = props;
  return (
    <Select
      onChange={(e) => onChange(e.target.value)}
      isFullWidth
      onBlur={() => {
        onBlur();
      }}
      value={value}
      // multiple
      {...rest}
    >
      {options &&
        options.map((op, i) => (
          <option value={valueKey ? op[valueKey] : op} key={`option-${i}`}>
            {labelKey ? op[labelKey] : op}
          </option>
        ))}
    </Select>
  );
};

export default NativeSelectField;
