import {
  Badge,
  Box,
  Button,
  Flex,
  Stack,
  Tag,
  Text,
  MenuItem,
  useColorModeValue,
} from '@chakra-ui/react';

import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import HeaderWithActions from '../../components/HeaderWithActions';
import ListView from '../../components/ListView';
import Meta from '../../components/Meta';
import {
  useSchemaQuery,
  useSchemaMutation,
} from '../../react-apollo-introspection';
import { DELETE, FETCH_ALL, FIRE } from './graphql';
import { setup } from './setup';
import PopConfirm from '../../components/PopConfirm';

const List = ({ title }) => {
  const [fetchData, { loading, error, data }] = useSchemaQuery(
    'notifications_List'
  );

  const [
    fireNotify,
    { data: createData, error: createError },
  ] = useSchemaMutation(FIRE);

  const onFire = (id) => {
    fireNotify({
      variables: {
        _id: id,
      },
      onCompleted: () => window.location.reload(),
    });
  };

  const columns = [
    {
      Header: 'Notification',
      accessor: '_id',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex gap={5} align="center">
          <Link to={`/${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant="link">{row?.original?.message?.title} </Button>
          </Link>
        </Flex>
      ),
    },
    {
      Header: 'Fire',
      disableSortBy: true,
      Cell: ({ row }) => (
        <Flex
          gap={5}
          align="center"
          bg="#3182ce"
          w="fit-content"
          p="10px"
          color="white"
          fontWeight="700"
          fontSize="1rem"
          rounded="8px"
          cursor="pointer"
        >
          <PopConfirm
            okText="Fire"
            onConfirm={() => onFire(row.original[setup.accessor])}
            okButtonProps={{
              variantColor: 'error',
            }}
            cancelButtonProps={{
              variantColor: 'gray',
            }}
            title="Are you sure you want to Fire this  Notification?  This cannot be undone."
          >
            Fire Notification
          </PopConfirm>
        </Flex>
      ),
    },
  ];

  return (
    <Meta title={setup.title}>
      <HeaderWithActions title={setup.title}>
        <Stack ml="auto" isInline spacing="15px">
          {setup.canAddNew && (
            <Link to={`/${setup.model}/create`}>
              <Button>Add {setup.singular}</Button>
            </Link>
          )}
        </Stack>
      </HeaderWithActions>
      <Box
        mt="25px"
        bg={useColorModeValue('white', 'gray.700')}
        shadow="base"
        rounded={{
          base: 'md',
          sm: 'xl',
        }}
        w="100%"
      >
        <ListView
          locationFilter={true}
          setup={setup}
          fetchData={fetchData}
          data={data?.[FETCH_ALL]}
          loading={loading}
          error={error}
          gqlDelete={DELETE}
          columns={columns}
        />
      </Box>
    </Meta>
  );
};

export default List;
