import React, { useState, useEffect, useCallback } from 'react';
import { Input, Button, Flex } from '@chakra-ui/react';
import AsyncSelect from 'react-select/async';
import { useSchemaQuery } from '../../react-apollo-introspection';
import { tableAtom, tableFilterAtom } from './atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { gql, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';

const GET_PLACES = gql`
  query Places_List(
    $filter: FilterFindManyplacesInput
    $regexp: places_regexp_OTC
  ) {
    places_List(filter: $filter, regexp: $regexp) {
      items {
        _id
        name
      }
    }
  }
`;
const CustomPlaceSearch = ({ filters, searchForPlace }) => {
  const [placeName, setPlaceName] = useState(null);
  const [selected, setSelected] = useState(null);
  const [tableState, setTableState] = useRecoilState(tableAtom);
  const [fetchTypes, { data, loading, error }] = useLazyQuery(GET_PLACES);

  const handleLoadPlaces = async (inputValue, callback) => {
    const { data: placesData } = await fetchTypes({
      variables: {
        regexp: {
          name: inputValue,
        },
      },
    });
    callback(JSON.parse(JSON.stringify(placesData.places_List.items)));
  };

  const searchOptions = useCallback(
    debounce((inputValue, callback) => {
      if (!inputValue || inputValue?.length < 3) {
        return callback([]);
      }

      changeSearch(inputValue, callback);
    }, 500),
    []
  );

  const changeSearch = (inputValue, callback) => {
    const tempArray = [];
    fetchTypes({
      variables: {
        regexp: {
          name: inputValue,
        },
      },
      onCompleted: (res) => {
        res?.places_List?.items.forEach((element) => {
          tempArray.push({
            label: element.name,
            value: element._id,
          });

          return callback(tempArray);
        });
      },
    });
  };

  useEffect(() => {
    if (!selected) {
      searchForPlace(null);
    }
  }, [selected]);
  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      flex: 'wrap',
    }),
  };
  return (
    <Flex w='full' flexDirection={'row'} gap={'10px'} px='10px'>
      <AsyncSelect
        styles={customStyles}
        placeholder='Search via places'
        isClearable
        isSearchable
        value={selected}
        getOptionLabel={(o) => o?.name}
        getOptionValue={(o) => o?._id}
        onChange={(option) => {
          setSelected(option);
          searchForPlace(option._id);
        }}
        loadOptions={handleLoadPlaces}
      />
    </Flex>
  );
};

//
export default CustomPlaceSearch;
