import { gql, useMutation } from '@apollo/client';
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';

const RESEND = gql`
    mutation ResendInvitation($user_id: ID!, $client_id: ID!) {
        resendInvitation(user_id: $user_id, client_id: $client_id) {
            email
        }
    }
`;

const ResendInvite = ({ user }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id } = useParams();
    const toast = useToast();

    const [resendInvitation, { loading }] = useMutation(RESEND);

    return (
        <>
            <Button
                size='xs'
                variant='outline'
                colorScheme='gray'
                onClick={() => onOpen()}
                isDisabled={loading}
                isLoading={loading}
            >
                Resend Invitation
            </Button>
            {isOpen && (
                <Modal isCentered isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalCloseButton />
                    <ModalContent>
                        <ModalHeader>Resend invitation</ModalHeader>
                        <ModalBody>
                            <Text mb='25px'>
                                Are you sure you want to resend the email
                                invitation to this user?
                            </Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme='green'
                                isDisabled={loading}
                                isLoading={loading}
                                onClick={() =>
                                    resendInvitation({
                                        variables: {
                                            user_id: user._id,
                                            client_id: id,
                                        },
                                        refetchQueries: ['GetClient'],
                                        onCompleted: () => {
                                            onClose();
                                            toast({
                                                status: 'success',
                                                description: 'Invitation sent',
                                            });
                                        },
                                    })
                                }
                            >
                                Send invite
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
};

export default ResendInvite;
